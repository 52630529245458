$color: #0874d8;

.barChart {
  display: flex;
  flex-direction: column;
  height: 368px;
}

.columns {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 24px 0;

  gap: 15px;
}

.column {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(166, 206, 227, .2);
}

.indicator {
  width: 100%;
  transition: height 1s ease;
  border-radius: 4px;
  background-color: $color;
}

.value {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: -5px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  transform: translateY(-100%);
}

.labelWrapper {
  position: absolute;
  right: 0;
  bottom: -24px;
  left: 0;
  display: flex;
  justify-content: center;
  &:hover .description {
    display: block;
  }
}

.label {
  font-size: 10px;
  transition: transform .2s;
}

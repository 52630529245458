.widgetPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #efe9ea;
  border: 1px solid #efe9ea;
  opacity: 0.5;
}

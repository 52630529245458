.cell > div {
  overflow: initial;
}

.wrapper {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.lineChartWrapper {
  width: 100%;
  height: 100%;
}

.barChartWrapper {
  position: absolute;
  z-index: 100;
  right: 0;
  padding-top: 15px;
  padding-right: 30px;
  background-color: var(--background-secondary-color);

  &.top {
    top: -10px;
    transform: translateY(-100%);
  }

  &.bottom {
    bottom: -10px;
    transform: translateY(100%);
  }
}

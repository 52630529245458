@import "src/screenSizes";

.widgetsWrapperOuter {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
}

.speedometer {
  width: 45%;
}

.table{
  width: 55%;
}

@media (max-width: $tabletScreen) {
  .widgetsWrapperOuter{
    flex-direction: column;
  }
  .speedometer,
  .table {
    width: 100%;
  }
}

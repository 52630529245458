.dashboardDetailsModal {
  display: flex;
  flex-direction: column;

  gap: 15px;
}

.title {
  display: flex;
  align-items: center;
  width: 630px;
  max-width: 100%;

  gap: 5px;
}

.content {
  display: flex;
  flex-direction: column;

  gap: 5px;
}

.option {
  color: var(--font-disabled-color);
  span {
    font-weight: 500;
    line-height: 20px;
    color: var(--font-main-color);
  }
}

.usedSearches {
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 0;
  padding-left: 15px;

  gap: 5px;
  li {
    width: 100%;
    max-width: calc(630px - 48px);
  }
  span {
    display: inline-block;
    margin-right: 5px;
  }
}

.doneButton {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-left: auto;
  padding: 5px 15px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--graphite-dark-color);
}

[dark-theme] {
  .option {
    color: var(--dark-theme-border-light-color);
    span {
      font-weight: 500;
      line-height: 20px;
      color: var(--dark-theme-font-main-color);
    }
  }
}

.headerToggler {
  flex: 0 0 auto;
  cursor: pointer;
  transition: transform .2s;
  border-radius: 40px;
  background-color: #ededed;

  &.isBodyCollapsed {
    transform: rotate(90deg);
  }
}

[dark-theme] {
  .headerToggler {
    background-color: var(--dark-theme-graphite-main-color);
  }
}

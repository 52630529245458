.tableWrapperPreview {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tableWrapperPreviewSection {
  margin-left: 15px;
}

.tableWrapperPreviewTitleValue {
  font-size: 65px ;
  font-weight: 700;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.tableWrapperPreviewSubTitle {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.tableWrapperPreviewSubTitleLeftValue,
.tableWrapperPreviewSubTitleRightValue {
  font-size: 30px;
  font-weight: 500;
}

.tableWrapperPreviewSubTitleLeftWrapper,
.tableWrapperPreviewSubTitleRightWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.negative {
  color: var(--pink-main-color);
}

.neutral {
  color: #e2b173;
}

.positive {
  color: #62b06f;
}

.brandName {
  font-weight: 700;
  margin-left: 5px;
}

@import "src/screenSizes";

div[class~="Toastify__toast"] {
  width: fit-content;
  min-height: auto;
  margin: 10px auto 0;
  padding: 0;
  border: 1px solid;
  border-radius: 4px;

  & > button {
    align-self: center;
    margin: 10px;
    padding: 5px 15px;
    color: var(--font-light-color);
    border-radius: 4px;
    cursor: pointer;
  }
}

div[class~="Toastify__toast-container"] {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
  min-width: 320px;
  width: auto;
  max-width: 395px;
}

div[class~="Toastify__toast-body"] {
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 10px;

  gap: 7px;

  div[class~="Toastify__toast-icon"] {
    width: auto;
    margin: 0;
  }

  div:last-child {
    flex: unset;
  }
}

div[class~="Toastify__toast--success"] {
  color: var(--notification-success-font-color);
  border-color: var(--notification-success-border-color);
  background-color: var(--notification-success-background-color);

  & > button {
    background-color: var(--notification-success-button-color);
  }
}

div[class~="Toastify__toast--warning"] {
  color: var(--notification-warning-font-color);
  border-color: var(--notification-warning-border-color);
  background-color: var(--notification-warning-background-color);

  & > button {
    background-color: var(--notification-warning-button-color);
  }
}

div[class~="Toastify__toast--error"] {
  color: var(--notification-error-font-color);
  border-color: var(--notification-error-border-color);
  background-color: var(--notification-error-background-color);

  & > button {
    background-color: var(--notification-error-button-color);
  }
}

div[class~="Toastify__toast--info"] {
  color: var(--notification-info-font-color);
  border-color: var(--notification-info-border-color);
  background-color: var(--notification-info-background-color);

  & > button {
    background-color: var(--notification-info-button-color);
  }
}

@media screen and (max-width: $mobileScreen) {
  div[class~="Toastify__toast-container"] {
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

$iconSize: 15px;

.preReleaseLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 50px;
  background: var(--pink-main-color);
}

.preReleaseLabelDefault {
  width: 45px;
}

.preReleaseLabelCompact {
  height: $iconSize;
  width: $iconSize;
}

.preReleaseLabelContent {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: var(--font-light-color);
}

.preReleaseLabelContentDefault {
  text-transform: uppercase;
}

.awarenessPieChart {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.labels {
  display: flex;
  justify-content: center;
  height: 24px;
}

.label {
  display: flex;
  align-items: center;
  color: currentColor;
  font-size: 10px;
  white-space: nowrap;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.labelIcon {
  margin-right: 4px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.chartsWrapper {
  flex: 1;
  margin: 16px 0 24px;
  display: grid;
  grid-gap: 30px;
  align-items: center;
  &For1 {
    grid-template: "chart0" auto/ auto;
  }
  &For3 {
    grid-template:
      "chart0 chart1" auto
      "chart0 chart2" auto / auto auto;
  }
  &For5 {
    grid-template:
      "chart0 chart1 chart3" auto
      "chart0 chart2 chart4" auto / auto auto auto;
  }
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brandName {
  margin-top: 4px;
  display: flex;
  justify-content: center;
  height: 16px;
  color: currentColor;
  font-size: 12px;
  white-space: nowrap;
}

.awarenessPieChartPage {
  .chartsWrapper {
    flex: 1;
    margin: 16px 0 24px;
    display: grid;
    grid-gap: 40px;
    .chartWrapper {
      &:nth-child(1) {
        width: 352px !important;
        height: 352px !important;
      }
      &:not(:nth-child(1)) {
        width: 156px !important;
        height: 156px !important;
      }
    }
    &For1 {
      grid-template: "chart0" auto/ auto;
    }
    &For3 {
      grid-template:
        "chart0 chart0" auto
        "chart1 chart2" auto / auto auto;
    }
    &For5 {
      grid-template:
        "chart0 chart0" auto
        "chart1 chart2" auto
        "chart3 chart4" auto / auto auto;
    }
  }
}

@media screen and (max-width: 767.99px) {
  .awarenessPieChartPage {
    .chartsWrapper {
      .chartWrapper {
        &:nth-child(1) {
          width: 260px !important;
          height: 260px !important;
        }
        &:not(:nth-child(1)) {
          width: 88px !important;
          height: 88px !important;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .awarenessPieChartPage {
    .chartsWrapper {
      &For3 {
        grid-template:
          "chart0 chart1" auto
          "chart0 chart2" auto / auto auto;
      }
      &For5 {
        grid-template:
          "chart0 chart0 chart1" auto
          "chart0 chart0 chart2" auto
          "chart3 chart4 placeholder" auto / auto auto auto;
      }
    }
  }
}

@media (min-width: 1100px) {
  .awarenessPieChartPage {
    .chartsWrapper {
      &For5 {
        grid-template:
          "chart0 chart1 chart3" auto
          "chart0 chart2 chart4" auto / auto auto auto;
      }
    }
  }
}

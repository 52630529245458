.tagCloud {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.word {
  position: relative;
  display: inline-flex;
  max-width: 100%;
  margin: 15px;
  border-radius: 5px;
  &:hover {
    .name {
      opacity: 0;
    }
    .count {
      opacity: 1;
    }
  }
}

.name,
.count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in;
}

.name {
  opacity: 1;
}

.count {
  position: absolute;
  opacity: 0;
}

.triangle {
  position: absolute;
  z-index: 0;
  bottom: 1px;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, 100%);
}

.dataRobustnessScore {
  position: relative;
  display: flex;
  width: fit-content;
}

.ticks {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 30px;
}

.tick {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  font-size: 12px;
}

.cells {
  display: grid;
  grid-gap: 2px;
  direction: rtl;
}

.cell {
  transition: all 0.1s ease;
}

.dividers {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 148px;
}

.divider {
  display: flex;
  align-items: center;
  height: 0;
  & > div {
    &:nth-child(1) {
      width: 72px;
      height: 1px;
      background-color: #707070;
    }
    &:nth-child(2) {
      margin-left: 4px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.pointer {
  position: absolute;
  right: 148px;
  transform: translate(50%, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  color: #efe9ea;
  & > div {
    &:nth-child(1) {
      height: 1px;
      right: 20px;
      background-color: var(--pink-main-color);
    }
    &:nth-child(2) {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      background-color: var(--pink-main-color);
      border-radius: 50%;
    }
  }
}

@import "src/screenSizes";

.editFolderModal {
  width: 340px;
  max-width: 100%;
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  gap: 10px;
  svg {
    color: var(--success-main-color);
  }
}

.editFolderForm {
  display: flex;
  flex-direction: column;

  gap: 30px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  button {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 5px 15px;
    border-radius: 4px;
    &[type = "submit"] {
      color: var(--font-light-color);
      background-color: var(--success-main-color);
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .editFolderModal {
    width: initial;
  }
}

$iconSize: 20px;

.multiSelect {
  font-size: 14px;
  position: relative;
  height: 40px;

  &:focus-within > .input {
    border-color: var(--border-main-color);
  }
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  background-color: var(--background-main-color);
}

[dark-theme] {
  .multiSelect:focus-within > .input {
    border-color: var(--dark-theme-border-main-color);
  }

  .input {
    border-color: var(--dark-theme-border-dark-color);
    background-color: var(--dark-theme-background-dark-color);
  }

  .placeholder {
    color: var(--dark-theme-font-disabled-color);
  }
}

.errorMessage {
  padding-left: 15px;
  color: var(--pink-main-color);
}

.label,
.placeholder {
  line-height: 40px;
  overflow: hidden;
  width: calc(100% - 44px);
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.placeholder {
  color: var(--font-disabled-color);
}

.arrowIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  & > * {
    transition: all .2s;
    opacity: .75;

    stroke-width: 1;
  }
}

.popup {
  position: absolute;
  z-index: 3;
  right: 0;
  left: 0;
  display: flex;
  overflow: auto;
  width: 100%;
  max-height: 250px;
  border-radius: 4px;
  background-color: var(--background-main-color);
  box-shadow: 0 3px 6px var(--main-box-shadow-color);
  &Up {
    bottom: 44px;
  }
  &Down {
    top: 44px;
  }
}

[dark-theme] {
  .popup {
    background-color: var(--dark-theme-background-dark-color);
  }
}

.filterInput {
  font-size: 14px;
  font-weight: inherit;
  width: calc(100% - 16px);
  height: 32px;
  margin: 8px;
  padding: 0 8px;
  color: inherit;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .1);
  &::placeholder {
    opacity: .5;
    color: inherit;
  }
}

.options {
  overflow-y: auto;
  flex: 1 1;
  & > div {
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .025);
  }
  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 0;
    background-color: rgba(0, 0, 0, .1);
  }
}

%option {
  line-height: 40px;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  opacity: .75;
}

.option {
  display: flex;
  align-items: center;
  transition: all .2s;
  position: relative;

  gap: 5px;
  @extend %option;

  svg {
    height: $iconSize;
    width: $iconSize;
  }

  span {
    overflow: hidden;
    flex: 1 1;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:disabled {
    opacity: .3;
    &:hover {
      opacity: .3;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.allSelected {
  opacity: 1;
}

.popupOptions {
  width: 100%;
}

.popupUp .popupOptions {
  display: flex;
  flex-direction: column-reverse;
}

.noOptions {
  @extend %option;
}

.allUnselectedText {
  display: flex;
  align-items: flex-start;
  margin: 10px;
  padding: 10px;
  background-color: #FFF6CC;
  color: var(--graphite-dark-color);
  svg {
    color: var(--warning-main-color);
    min-width: 20px;
    min-height: 20px;
    margin-right: 5px;
  }
}

.checkbox:not(disabled) {
  cursor: pointer;
}

.checkbox.disabled {
  opacity: .25;
}

[dark-theme] {
  .checkboxChecked {
    color: var(--dark-theme-font-main-color);
  }
}

.basicModal {
  font-size: 18px;
  padding-top: 20px;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  .button {
    font-size: 16px;
    overflow: hidden;
    min-width: 112px;
    height: 32px;
    margin-top: 24px;
    padding: 0 16px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    &:nth-child(2) {
      margin-left: 24px;
    }
  }
}

@media (min-width: 768px) {
  .buttonsWrapper {
    .button {
      &:nth-child(2) {
        margin-left: 40px;
      }
    }
  }
}

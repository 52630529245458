.downloadButtons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 24px;

  & > button {
    margin-left: unset;
  }
}

@import "src/screenSizes";

.timePlotterForm {
  padding: 15px;
}

.form {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  display: grid;
  margin-bottom: 15px;

  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px 15px;

  .select {
    & > .input {
      border: 0;
    }
  }

  .textarea {
    height: 100px;

    grid-area: textarea;
  }

  .text {
    display: inline-block;
    padding: 5px 10px;
    color: var(--gold-main-color);
  }

  .datePickerContainer {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    input {
      font-size: inherit;
      width: 100%;
      padding: 12px 32px 12px 15px;
      text-overflow: ellipsis;
      border: 1px solid var(--border-light-color);
      border-radius: 4px;
    }
  }

  .datePickerWrapper {
    width: 100%;
  }

  .notes {
    grid-column: 1/-1;
  }

  div[class*="withError_text"]::after {
    font-size: 11px;
  }
}

.controlButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  text-align: end;

  gap: 15px;

  button {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    padding: 10px 20px;
    border-radius: 4px;
  }

  .discardButton {
    background-color: var(--error-main-color);
  }

  .saveButton {
    background-color: var(--success-main-color);
  }
}

@media screen and (max-width: $tabletScreen) {
  .form {
    display: flex;
    flex-wrap: wrap;

    grid-gap: 30px;

    > div {
      flex: 25%;
    }

    > div:nth-last-child(2),
    > div:nth-last-child(1) {
      flex: 100%;
    }

    .datePickerContainer {
      flex: 100%;

      grid-gap: 30px;
    }
  }

  .controlButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
}

@media screen and (max-width: $mobileScreen) {
  .form {
    display: grid;

    grid-gap: 30px;
    grid-template-columns: 1fr;

    .datePickerContainer {
      display: grid;

      grid-gap: 30px;
      grid-template-columns: 1fr;
    }
  }

  .controlButtons {
    margin-top: 35px;
  }
}

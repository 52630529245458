.welcomeModal {
  width: 294px;
}

@media (min-width: 768px) {
  .welcomeModal {
    width: 704px;
  }
}

.content {
  display: grid;
  padding: 0 16px 16px;

  grid-template: "textArea" auto
  "imageArea" 80px/ 100%;
  row-gap: 16px;
}

@media (min-width: 768px) {
  .content {
    padding: 0 40px;

    grid-template: "textArea" auto
    "imageArea" 240px/ 100%;
    row-gap: 24px;
  }
}

.imageArea {
  grid-area: imageArea;
  .image {
    width: 100%;
    height: 100%;
  }
}

.textArea {
  grid-area: textArea;
}

.title {
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
}

@media (min-width: 992px) {
  .title {
    font-size: 32px;
    margin-bottom: 24px;
  }
}

.text {
  font-size: 14px;
  text-align: center;
}

@media (min-width: 768px) {
  .text {
    font-size: 18px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

@media (min-width: 768px) {
  .footer {
    padding: 40px;
  }
}

.returnBackModal {
  display: grid;
  grid-template:
    "pink" auto
    "white" 370px / 296px;
  column-gap: 32px;
  row-gap: 32px;
}

.returnBackModal.withoutSearch {
  display: flex;
  max-width: 500px;
  height: 450px;
  .whiteSide {
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: center 120px;
  }
  .closeIcon div div div {
    background-color: #20263b !important;
  }
}

@media screen and (min-width: 768px) {
  .returnBackModal.withoutSearch {
    height: initial;
    .whiteSide {
      background-position: -160px;
    }
  }
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.whiteSide {
  grid-area: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-image: url("../../../images/birds/bird.png");
  background-position: center 80px;
  background-repeat: no-repeat;
  background-size: 480px;
  & > * {
    &:nth-child(1) {
      font-size: 24px;
    }
    &:nth-child(2) {
      margin-top: 16px;
      font-size: 16px;
      color: currentColor;
    }
  }
}

.pinkSide {
  grid-area: pink;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  color: currentColor;
}

@media (min-width: 768px) {
  .returnBackModal {
    grid-template: "white pink" 336px / 416px 288px;
  }
  .whiteSide {
    justify-content: center;
    padding-left: 160px;
    background-position: -160px;
  }
}

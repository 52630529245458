@import "src/screenSizes";

.renameModal {
  display: flex;
  align-items: center;
  margin: 24px 0;
}

.inputWrapper{
  margin: 0 10px;
}

.input {
  width: 250px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.greenButton {
  font-size: 16px;
  width: 112px;
  min-width: 112px;
  height: 40px;
  color: var(--white-dark-color);
  border-radius: 4px;
  background-color: var(--success-main-color);
}

.error{
  margin-top: 5px;
  color: var(--pink-main-color);
  font-size: 12px;
}

@media screen and (max-width: $mobileScreen) {
  .label {
    margin-right: auto;
  }
  .renameModal {
    flex-direction: column;
  }
  .input {
    margin: 30px 0;
  }
  .greenButton {
    margin-left: auto;
  }
}

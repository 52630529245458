@import "../../generalStyles/generalWidgetStyles.module.scss";
@import "../../generalStyles/generalLineChartStyles.scss";

.widgetWrapper {
  width: auto;
  &LineChart {
    width: 100%;
  }
}

.pieChart {
  width: 264px;
}

@media (min-width: 768px) {
  .pieChart {
    width: 416px;
  }
}

@media (min-width: 1200px) {
  .widgetOptions {
    position: absolute;
    right: 0;
  }
}

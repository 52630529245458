.verticalFunnelChart {
  display: grid;
  grid-template-rows: min-content auto;
}

.title {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;

  div {
    &:nth-child(1) {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-child(2) {
      flex: 1;
      margin-left: 8px;
      border-top: 1px solid;
    }
  }
}

.rows {
  display: flex;
  flex-direction: column;
}

.rowWrapper {
  flex: 1;
  position: relative;
  border-bottom: 1px solid rgba(#707070, 0.25);
}

.row {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.shapeWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.75;
}

.labelWrapper {
  position: absolute;
  left: 0;
  // max-width: 50%;
  max-width: 100%;
  display: flex;
  align-items: center;
  div {
    &:nth-child(1) {
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      font-size: 10px;
      font-weight: 500;
      color: white;
    }
    &:nth-child(2) {
      max-width: calc(100% - 36px);
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
      text-overflow: ellipsis;
      font-size: 10px;
      font-weight: 700;
    }
  }
}

// .rateWrapper {
//   position: absolute;
//   right: 0;
//   max-width: 50%;
//   font-size: 10px;
//   overflow: hidden;
//   text-transform: uppercase;
//   text-overflow: ellipsis;
//   span {
//     &:nth-child(1) {
//       font-weight: 700;
//       color: rgb(239, 233, 234);
//     }
//     &:nth-child(2) {
//       font-weight: 300;
//       color: rgba(239, 233, 234, 0.5);
//     }
//   }
// }

// .pointer {
//   position: absolute;
//   left: 50%;
//   top: 75%;
//   width: 35%;
//   height: 65%;
//   border-width: 1px;
//   border-style: solid;
//   border-left-color: transparent;
//   border-bottom-color: transparent;
// }

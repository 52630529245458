.widget {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.widgetTitle {
  margin-bottom: 15px;
}

.content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  background-color: var(--background-main-color);
}

.widgetWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 24px;
}

.widgetWrapperOuter {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 0 -24px;
}

.preloader {
  height: 100%;
  margin: auto;
}

[dark-theme] {
  .content {
    background-color: var(--dark-theme-background-dark-color);
  }
}

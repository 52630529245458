.dashboardPage {
  flex: 1 1;

  .content {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}

.editButton {
  font-weight: 500;
}

.buttonContent {
  display: flex;
  align-items: center;
}

.buttonText {
  font-weight: 500;
  margin-left: 5px;
}

.actions {
  display: flex;
  flex-direction: column;
  flex: 1 auto 100%;

  gap: 15px;
}

.rightControlPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 10px;
}

.leftControlPanel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 10px;

  .select {
    width: 100%;
    height: 32px;

    .popupDown {
      top: 32px;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  & > div {
    &:nth-child(1) {
      align-self: flex-end;
    }
    &:nth-child(2) {
      margin-top: 8px;
    }
  }
}

.detail {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    font-weight: 500;
  }
}

.dragIcon {
  position: absolute;
  right: 4px;
  bottom: 4px;
  height: 8px;
  opacity: .5;
}

.dropDown {
  background-color: var(--background-main-color);
}

.syncButtonLoading {
  & > svg {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}

@media (min-width: 768px) {
  .scrollButton {
    position: absolute;
    bottom: 0;
    margin-top: 0;
    transform: translateY(100%);
  }

  .header {
    position: sticky;
    z-index: 2;
    top: 0;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px 20px;
    & > div {
      &:nth-child(1) {
        align-self: initial;
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        margin-top: 0;
      }
    }
  }

  .editButton {
    font-weight: bold;
    margin-top: 0;
  }

  .select {
    width: 240px;
  }

  .detail {
    max-width: 120px;
    margin-top: 0;
  }

  .rightControlPanel {
    width: initial;
  }

  [dark-theme] {
    .header {
      background-color: var(--dark-theme-background-dark-color);
    }
  }
}

@media (min-width: 991px) {
  .actions {
    flex-direction: row;
    flex: 0 auto revert;
  }

  .leftControlPanel {
    .select {
      width: 240px;
    }
  }

  .rightControlPanel {
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
  }

  .detail {
    max-width: 200px;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

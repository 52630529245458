@import "src/screenSizes";
@import "../../generalStyles/generalWidgetStyles.module.scss";

.sentimentDrivers {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.widgetWrapper {
  width: auto;

  gap: 25px;
  &ListView {
    width: 100%;
  }
}

.description {
  font-size: 20px;
  margin-top: 24px;
}

.widgetWrapperOuter {
  position: relative;
  flex: 1 1;
  justify-content: center;
}

.sentimentDriversTagCloud {
  align-items: flex-start;
  max-width: 500px;
  & > div {
    margin: initial;
  }
}

.smallTip {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}

.error {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  height: 400px;
  margin: auto;
  text-align: center;
}

@media (max-width: $tabletScreen) {
  .sentimentDriversTagCloud {
    height: initial;
    margin: auto;
  }

  .widgetWrapper {
    flex-direction: column;
  }
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-datepicker/dist/react-datepicker.css";

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
}

button {
  font-weight: inherit;
  padding: 0;
  cursor: pointer;
  color: inherit;
  border: none;
  background-color: transparent;
  &:disabled {
    cursor: not-allowed;
  }
}

input {
  border: none;
}

a {
  color: inherit;
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
}

svg {
  fill: currentColor;
}

::-webkit-scrollbar {
  width: 17px;
}

::-webkit-scrollbar-track {
  background-color: var(--white-dark-color);
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 8px;
  background-color: #d7d9dd;
  background-clip: content-box;
}

[dark-theme] {
  ::-webkit-scrollbar-track {
    background-color: var(--dark-theme-background-light-color);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--graphite-dark-color);
  }
}

// GLOBAL CLASSES
.safeFont {
  * {
    font-family: sans-serif !important;
  }
}

:root {
  --app-height: 100%;

  // ACTION BAR
  --actionBar-height: 60px;
  --additional-button-width: 150px;

  // BOX SHADOW
  --main-box-shadow-color: rgba(0, 0, 0, 0.16);
  --main-box-hover-shadow-color: rgba(31, 31, 31, .25);

  // <-------- COLORS ---------
  // PRIMARY
  --white-main-color: #FFFFFF;
  --white-dark-color: #F7F7F7;
  --gold-main-color: #CECE84;
  --pink-main-color: #EF336A;
  --pink-dark-color: #CA3460;
  --graphite-light-color: #8F9EC6;
  --graphite-main-color: #313B5C;
  --graphite-dark-color: #242B43;

  // ERROR
  --error-light-color: #FFE4D9;
  --error-main-color: #FF4451;
  --error-dark-color: #E5303C;

  // SUCCESS
  --success-light-color: #E7FCE4;
  --success-main-color: #41B167;
  --success-dark-color: #2F985D;

  // WARNING
  --warning-light-color: #FFFCEA;
  --warning-main-color: #FFB800;
  // --warning-dark-color: ???

  // INFO
  --info-light-color: #F5F8FF;
  --info-main-color: #1753FF;
  // --info-dark-color: ???

  // FONT
  --font-main-color: #1C1C1F;
  --font-light-color: #FFFFFF;
  --font-secondary-color: #A8AAB3;
  --font-disabled-color: #6C6D75;
  --font-active-color: #0C34E7;
  --font-info-color: #4215C5;

  // BORDER
  --border-main-color: #A3AAC7;
  --border-light-color: #EAEBF1;

  // BACKGROUND
  --background-main-color: #FFFFFF;
  --background-secondary-color: #FCFCFC;

  // BUTTON
  --button-background-main-color: #F7F7F7;
  --button-background-secondary-color: #EDEDED;
  --button-background-disabled-color: #DFE1E8;

  // <--- NOTIFICATION ---
  // SUCCESS
  --notification-success-font-color: var(--font-main-color);
  --notification-success-border-color: var(--success-main-color);
  --notification-success-button-color: var(--success-main-color);
  --notification-success-background-color: var(--success-light-color);

  // ERROR
  --notification-error-font-color: var(--font-main-color);
  --notification-error-border-color: var(--error-main-color);
  --notification-error-button-color: var(--error-main-color);
  --notification-error-background-color: var(--error-light-color);

  // INFO
  --notification-info-font-color: var(--font-main-color);
  --notification-info-border-color: var(--info-main-color);
  --notification-info-button-color: var(--info-main-color);
  --notification-info-background-color: var(--info-light-color);

  // WARNING
  --notification-warning-font-color: var(--font-main-color);
  --notification-warning-border-color: var(--warning-main-color);
  --notification-warning-button-color: var(--warning-main-color);
  --notification-warning-background-color: var(--warning-light-color);
  // --- NOTIFICATION --->

  // <----- DARK THEME -----
  // PRIMARY
  --dark-theme-graphite-light-color: #616CAD;
  --dark-theme-graphite-main-color: #353F5F;

  // INFO
  --dark-theme-info-light-color: #7ACFFF;
  --dark-theme-info-main-color: #374C6C;
  // --dark-theme-info-dark-color: ???

  // FONT
  --dark-theme-font-main-color: #FFFFFF;
  --dark-theme-font-disabled-color: #AAB5CC;
  --dark-theme-font-info-color: #7ACFFF;
  --dark-theme-font-active-color: #9EC8FF;

  // BACKGROUND
  --dark-theme-background-main-color: #1F263B;
  --dark-theme-background-light-color: #313B5C;
  --dark-theme-background-dark-color: #2B3552;
  --dark-theme-background-accent-color: #3366FF;

  // BORDER
  --dark-theme-border-light-color: #EAEBF1;
  --dark-theme-border-main-color: #A3AAC7;
  --dark-theme-border-dark-color: #525B8F;

  // <--- NOTIFICATION ---
  // INFO
  --dark-theme-notification-info-font-color: var(--dark-theme-font-main-color);
  --dark-theme-notification-info-border-color: var(--dark-theme-info-light-color);
  --dark-theme-notification-info-button-color: var(--dark-theme-info-light-color);
  --dark-theme-notification-info-background-color: var(--dark-theme-info-main-color);
  // --- NOTIFICATION --->
  // ----- DARK THEME ----->
  // --------- COLORS -------->
}

.checkboxSection {
  display: flex;
}

.checkboxItem {
  display: flex;
  align-items: center;
}
.checkboxInput {
  cursor: pointer;
}

.checkboxItem:first-child {
  margin-right: 25px;
}

.checkboxItemList {
  display: flex;
}

@import "src/screenSizes";

.sentimentDriversReviewData {
  display: flex;
  min-height: 575px;
  padding-top: 20px;
}

.reviewData {
  position: static !important;
  & > button {
    top: 8px;
    left: 12px;
    transform: initial;
  }
}

.select {
  z-index: 3;
  max-width: 600px;
}

.filter {
  position: relative;
  margin: 30px 0;
  svg {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }
  .input {
    max-width: 600px;
    & > input {
      padding-left: 50px;
    }
  }
}

.wordsWrapper {
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.searchTable {
  margin-bottom: 30px;
  & > div {
    overflow: initial;
    & > div:nth-child(1) {
      overflow: auto;
    }
  }
}

.excluded {
  display: flex;
  align-items: flex-end;
  width: 100%;
  & > button {
    font-size: 20px;
    line-height: 24px;
    margin-left: auto;
    padding: 10px 20px;
    color: var(--font-light-color);
    border-radius: 5px;
    background: var(--success-main-color);
  }
  & > button:disabled {
    color: var(--font-disabled-color);
    background: var(--button-background-disabled-color);
  }
}

.excludedWrapper {
  display: grid;
  align-items: flex-start;
  width: calc(100% - 165px);
  height: 230px;
  padding: 15px;
  border: 1px solid #bcbec5;
  border-radius: 5px;

  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 30px;
}

.excludedArticles,
.excludedWords {
  display: flex;
  overflow: auto;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  & > p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 15px;
    letter-spacing: .7px;
    opacity: .5;
  }
}

.excludedWordsWrapper,
.excludedArticlesWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.excludedItem {
  margin-right: 10px;
  margin-bottom: 10px;
}

.error {
  position: relative;
  display: flex;
  align-items: center;
  width: 600px;
  height: 200px;
  margin: auto;
  p {
    font-size: 24px;
    width: calc(100% - 200px);
    text-align: center;
  }
  .image {
    position: absolute;
    right: -100px;
  }
}

.brandButton {
  height: 28px;
  margin: 0 10px 10px 0;
  padding: 0 16px;
  transition: opacity .2s;
  opacity: .75;
  border: 1px solid transparent;
  border-radius: 6px;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    opacity: 1;
  }
  &Active {
    opacity: 1;
    border-color: #8e8e63;
  }
}

.switchWarningWrapper {
  position: absolute;
  z-index: 10;
  top: -40px;
  left: -24px;
  width: calc(100% + 48px);
  height: calc(100% + 64px);
  background-color: rgba(0, 0, 0, .5);
}

.switchWarning {
  position: absolute;
  top: 35%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -50%);
  background-color: var(--white-dark-color);
  p {
    font-size: 20px;
    text-align: center;
  }
}

.preloader {
  position: absolute;
  z-index: 20;
  top: 0;
  left: -24px;
  width: calc(100% + 48px);
  height: calc(100% + 24px);
  background-color: var(--background-main-color);
}

.innerModal {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.innerModalWrapper {
  position: absolute;
  top: 35%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -50%);
  background-color: var(--white-dark-color);
  p {
    font-size: 20px;
    text-align: center;
  }
}

.switchWarningButtons,
.parametersButtons {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  button {
    min-width: 100px;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    &:nth-child(1) {
      margin-right: 15px;
      background-color: var(--pink-main-color);
    }
    &:nth-child(2) {
      background: var(--success-main-color);
    }
  }
}

.parametersButtons button:nth-child(1) {
  margin-right: 0;
  background: var(--success-main-color);
}

.sentimentDriversReviewData .preloader {
  position: absolute;
  z-index: 20;
  top: 0;
  left: -24px;
  width: calc(100% + 48px);
  height: calc(100% + 24px);
  background-color: var(--background-main-color);
}

.parameter {
  height: 28px;
  margin: 0 10px 10px 0;
  padding: 0 16px;
  transition: opacity .2s;
  opacity: .5;
  border: 1px solid transparent;
  border-radius: 6px;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    opacity: 1;
  }
  &Active {
    opacity: 1;
    border-color: #8e8e63;
  }
}

.changedParametersTip {
  font-size: 13px;
}

.excludedArticlesPlaceHolder
  .excludedWordsPlaceHolder {
  color: var(--font-disabled-color);
}

.boldLabel {
  font-weight: 500;
}

[dark-theme] {
  .preloader,
  .switchWarning,
  .innerModalWrapper,
  .aboutThisBtn.active div::before {
    background-color: var(--dark-theme-background-dark-color);
  }
  .excluded {
    & > button:disabled {
      color: var(--dark-theme-font-disabled-color);
    }
  }

  .excludedArticlesPlaceHolder
  .excludedWordsPlaceHolder {
    color: var(--dark-theme-font-disabled-color);
  }
}

@media screen and (max-width: $tabletScreen) {
  .error {
    p {
      font-size: 16px;
      width: calc(100% - 100px);
      text-align: center;
    }
    .image {
      position: absolute;
      top: -25px;
      right: -100px;
      width: 50px;
      width: 300px;
    }
  }
}

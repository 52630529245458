.switchWarningWrapper {
  position: absolute;
  z-index: 10;
  top: -40px;
  left: -24px;
  width: calc(100% + 48px);
  height: calc(100% + 64px);
  background-color: rgba(0, 0, 0, .5);
}

.switchWarning {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -50%);
  background-color: var(--white-dark-color);
  p {
    font-size: 20px;
    text-align: center;
  }
}

.switchWarningButtons {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  button {
    min-width: 100px;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    &:nth-child(1) {
      margin-right: 15px;
      background-color: var(--pink-main-color);
    }
    &:nth-child(2) {
      background: var(--success-main-color);
    }
  }
}

[dark-theme] {
  .switchWarning {
    background-color: var(--dark-theme-background-dark-color);
  }
}

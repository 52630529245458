.keyBusinessDriversPreview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chartWrapper {
  flex: 1;
  padding: 8px;
}

.chart {
  height: 100% !important;
  cursor: pointer;
}

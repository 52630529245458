@import "src/screenSizes";

.textareaWrapper {
  font-size: 14px;
  line-height: 40px;
  position: relative;
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  background-color: var(--background-main-color);

  &.fillTextarea {
    border-color: var(--border-main-color);
  }

  textarea {
    font-size: inherit;
    font-weight: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px 15px;
    resize: none;
    color: inherit;
    border: none;
    background-color: transparent;

    &::placeholder {
      color: var(--font-disabled-color);
    }
  }

  svg {
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    color: var(--border-main-color);
  }
}

[dark-theme] {
  .textareaWrapper {
    border-color: var(--dark-theme-border-dark-color);
    background-color: var(--dark-theme-background-dark-color);

    &.fillTextarea {
      border-color: var(--dark-theme-border-main-color);
    }
  }

  textarea {
    &::placeholder {
      color: var(--dark-theme-font-disabled-color);
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .textareaWrapper {
    textarea {
      padding: 10px 14px;
    }
  }
}

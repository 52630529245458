.chartButton {
  font-size: 10px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

  &.selected {
    opacity: 0.5;
    cursor: default;
  }

  & > div {
    font-size: 10px;
    position: absolute;
    right: 2px;
    bottom: 0;
  }
}

.withError {
  position: relative;
}

.text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  pointer-events: none;

  &::after {
    content: attr(data-text);
    position: absolute;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    bottom: -18px;
    color: inherit;
  }
}

.error {
  color: var(--error-main-color);
  border-color: var(--error-main-color);
}

.info {
  color: var(--font-info-color);
  border-color: var(--font-info-color);
}

[dark-theme] {
  .info {
    color: var(--dark-theme-notification-info-font-color);
    border-color: var(--dark-theme-notification-info-border-color);
  }
}

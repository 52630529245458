.inputWithMenu {
  position: relative;
}

.inputWrapper > input {
  padding: 0 24px 0 16px;
}

.dropDown {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

%buttonGeneralStyles {
  position: absolute;
  z-index: 100;
  top: 400px;
}

.widgetContainer {
  display: flex;
  min-height: 100%;
}

.content {
  position: relative;
  display: flex;
  flex: 1;
  border-radius: 4px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
}

.widgetWrapper {
  display: flex;
  flex: 1 1;
}

.previousWidgetButton {
  left: -17.5px;
  transform: rotate(180deg);

  @extend %buttonGeneralStyles;
}

.nextWidgetButton {
  right: -17.5px;

  @extend %buttonGeneralStyles;
}

.downloadButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > button:not(:last-child) {
    margin-right: 10px;
  }
}

.error {
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: var(--background-main-color);
  box-shadow: 0 3px 6px var(--main-box-shadow-color);
}

[dark-theme] {
  .error {
    background-color: var(--dark-theme-background-dark-color);
  }
}

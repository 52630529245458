%buttonStyle {
  font-size: 16px;
  width: 90%;
  max-width: 150px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  opacity: 1;
  color: var(--font-light-color);
  border-radius: 5px;
  background: var(--success-main-color) 0 0 no-repeat padding-box;
}

.paymentModal {
  width: 1125px;
  max-width: 100%;
}

.title {
  font-size: 24px;
  margin: 0;
  letter-spacing: .52px;
  opacity: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 25px 0;
}

.tile {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 270px;
  border-radius: 5px;
  background-color: var(--background-main-color);
  box-shadow: 0 6px 20px rgba(0, 0, 0, .16);
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  svg {
    max-width: 55px;
    margin: 0 10px 25px 10px;
  }
  p {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 15px;
    letter-spacing: .52px;
    color: black;
  }
  a,
  button {
    @extend %buttonStyle;
  }
}

.paymentMethods {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
}

.links {
  display: flex;
  justify-content: center;
  a,
  button {
    @extend %buttonStyle;
  }
}

@media screen and (min-width: 1200px) {
  .paymentModal {
    padding: 50px;
  }
}

@media screen and (min-width: 768px) {
  .wrapper {
    display: grid;
    margin: 40px 0;

    gap: 110px;
    grid-template-columns: repeat(2, 1fr);
  }
  .tile {
    min-height: 370px;
    &:not(:last-child) {
      margin-bottom: 0;
    }
    p {
      font-size: 26px;
      margin-bottom: 45px;
    }
    a,
    button {
      max-width: 200px;
    }
    svg {
      max-width: 100%;
      margin: 0 10px 45px 10px;
    }
  }
}

@import "src/screenSizes";

@mixin breadcrumb-separator($size, $weight) {
  content: "";
  display: inline-block;
  width: $size;
  height: $size;
  border-color: inherit;
  border-right: $weight solid;
  border-top: $weight solid;
  margin: 0 10px;
}

@mixin breadcrumb-separator-right($size, $weight) {
  transform: translateY(-1px) translateX(-2px) rotate(45deg);

  @include breadcrumb-separator($size, $weight);
}

@mixin breadcrumb-separator-left($size, $weight) {
  transform: translateY(-1px) translateX(2px) rotate(225deg);;

  @include breadcrumb-separator($size, $weight);
}

$breadcrumb-size: 14px;
$breadcrumb-separator-size: 6px;
$breadcrumb-separator-weight: 1.5px;

.breadcrumbs {
  display: flex;
  overflow: hidden;

  a {
    font-size: $breadcrumb-size;
    color: var(--font-active-color);
    text-decoration: none;
  }

  span {
    font-size: $breadcrumb-size;
    color: var(--font-disabled-color);
  }
}

.breadcrumb {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:first-child:before {
    display: none;
  }

  &:before {
    @include breadcrumb-separator-right($breadcrumb-separator-size, $breadcrumb-separator-weight);
  }
}

[dark-theme] {
  .breadcrumbs {
    a {
      color: var(--dark-theme-font-active-color);
    }
  }
}

@media screen and (max-width: $tabletScreen) {
  .breadcrumbs {
    span {
      display: none;

      &:first-child {
        display: inline-block;
      }
    }

    .breadcrumb {
      & > a {
        &:before {
          @include breadcrumb-separator-left($breadcrumb-separator-size, $breadcrumb-separator-weight);

          margin-left: 5px;
        }
      }
    }
  }
}

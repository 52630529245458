@import "../../generalStyles/generalWidgetStyles.module.scss";
@import "../../generalStyles/generalLineChartStyles.scss";

.widgetWrapper {
  width: auto;
  &LineChart {
    width: 100%;
  }
}

.description {
  &Part1 {
    font-size: 20px;
    margin-top: 24px;
  }
  &Part2 {
    font-size: 10px;
    margin-top: 24px;
    text-align: center;
  }
  &Part3 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 40px;
    margin-bottom: 16px;
  }
}

@import "../../generalStyles/generalWidgetStyles.module.scss";

.widgetWrapperBar {
  max-width: 750px;
}

.parameters {
  margin-left: 8px;
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.whatDoesItMean {
  margin-top: 15px;
  .title {
    margin-bottom: 10px;
    color: #8e8e63;
  }
}

.parameter {
  font-size: 11px;
  display: flex;
  & > div {
    &:nth-child(1) {
      flex-shrink: 0;
      width: 12px;
      height: 12px;
      margin-right: 8px;
      border-radius: 50%;
    }
    &:nth-child(2) {
      & > span {
        &:nth-child(1) {
          font-weight: 500;
        }
        &:nth-child(2) {
          opacity: .75;
        }
      }
    }
  }
}

.note {
  font-size: 10px;
  margin-top: 24px;
  text-align: center;
}

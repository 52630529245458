.timePlotterButton {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 14px 12px 15px;
  text-align: center;
  color: var(--font-light-color);
  border-radius: 4px;
  background: var(--graphite-dark-color);
}

.timePlotterIcon {
  margin-right: 5px;
}

.timePlotterButton:hover {
  background: var(--graphite-main-color);
}

.timePlotterButton:active {
  background: var(--graphite-main-color);
}

.pieChartWithInnerLabels {
  position: relative;
  display: flex;
  align-items: center;
}

.chartWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.labels {
  position: absolute;
  overflow: auto;
  max-width: 60%;
  max-height: 50%;
  padding: 0 5px;
  &::-webkit-scrollbar {
    width: 10px;
  }
}

.label {
  font-size: 12px;
  position: relative;
  display: flex;
  align-items: center;

  gap: 4px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &Value {
    font-weight: 500;
    text-align: center;
  }
  &Icon {
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: var(--white-dark-color);
    border-radius: 50%;
  }
  &Text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.chart {
  width: 100%;
  height: 100%;
}

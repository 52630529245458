%tableCellStyles {
  &:first-of-type {
    width: 25%;
  }

  &:nth-of-type(2) {
    width: 20%;
  }
}

%fontStyles {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.createButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--graphite-dark-color);

  @extend %fontStyles;
  gap: 10px;

  &:disabled {
    opacity: .5;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  background-color: var(--background-main-color);

  & > *:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  & > *:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &.shortTableWrapper {
    margin: 0;
    color: var(--font-main-color);
  }
}

.tableWrapper {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  word-break: break-all;
  border-radius: 8px 8px 0 0;
  background-color: var(--background-main-color);

  .tableHeaderWrapper {
    box-shadow: 0 8px 8px rgba(31, 31, 31, .03);

    th {
      padding: 15px;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;

      @extend %tableCellStyles;
      @extend %fontStyles;

      &.sortable {
        position: relative;
        cursor: pointer;
        user-select: none;
        transition: transition 1s;

        .sortIconsWrapper {
          display: inline-flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 18px;
          height: 18px;
          margin-left: 2px;
          vertical-align: middle;
          border-radius: 2px;

          gap: 1.5px;

          &:hover {
            background-color: #ededed;
          }

          svg {
            width: 5px;
            height: 5px;
            opacity: .2;

            &:last-child {
              transform: rotate(180deg);
            }
          }

          &.activeAsc {
            svg {
              &:first-child {
                opacity: 1;
              }
            }
          }

          &.activeDesc {
            svg {
              &:last-child {
                opacity: 1;
              }
            }
          }
        }
      }

      &.columnAction,
      &.columnNumeric {
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  .tableBodyWrapper {
    tr {
      border-bottom: 1px solid var(--border-light-color);

      &:last-child {
        width: 100%;
        border-bottom: none;
      }

      &.subTableRow {
        td {
          &:first-of-type {
            padding-left: 70px;
          }
        }
      }
    }

    td {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      overflow: hidden;
      max-width: 45px;
      padding: 15px;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;

      @extend %tableCellStyles;

      & > span {
        display: flex;
        text-overflow: ellipsis;

        gap: 7.5px;

        & > svg {
          flex: 0 0 auto;
        }

        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &:last-of-type {
        position: relative;
        display: flex;
        overflow: visible;
        align-items: center;
        justify-content: flex-start;
        min-width: 100%;
        min-height: 50px;
        text-align: center;

        &.cellAction {
          position: relative;
          justify-content: center;
          margin: 0 auto;

          & > * {
            svg {
              display: flex;
              cursor: pointer;
            }
          }
        }

        & > .actionsMenuDropdown {
          position: absolute;
          right: 15px;
          margin-left: auto;
        }
      }

      &.cellAction {
        text-align: center;

        span {
          justify-content: center;
        }
      }

      &.cellNumeric {
        justify-content: center;
        margin: 0 auto;
        text-align: center;

        & > button {
          width: fit-content;
        }
      }

      .clickable {
        font-size: inherit;
        overflow: hidden;
        width: 100%;
        text-align: start;
        text-overflow: ellipsis;
        color: var(--font-active-color);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}

.emptyTableWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 15px;
  text-align: center;

  .emptyTableText {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--font-disabled-color);

    & > a {
      color: var(--font-active-color);
    }
  }
}

.tableBodyWrapperEmpty {
  tr {
    position: static;
  }

  td {
    padding: 10px 0;
    vertical-align: bottom;
  }
}

.tablePaginationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding: 15px;
  color: var(--font-disabled-color);
  border-top: 1px solid var(--border-light-color);
  background-color: var(--background-main-color);

  @extend %fontStyles;

  &.shortTablePaginationWrapper {
    padding: 5px 0 0 !important;
    border-top: none;

    ul {
      margin: 0;
    }
  }

  .itemsPerPage {
    display: flex;
    align-items: center;
    flex: 1;

    gap: 5px;

    .selectPerPage {
      & > *:last-child {
        width: fit-content;
      }

      .selectInputPerPage {
        position: static;
        align-items: center;
        border: none;

        gap: 5px;

        div {
          width: auto;
          padding: 0;
        }
      }
    }
  }

  .paginationContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    margin: 0;
    padding: 0;

    gap: 5px;

    li {
      display: inline-block;

      a {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 11px;
        cursor: pointer;
        transition: all .2s;
        color: var(--font-main-color);
        border: 1px solid transparent;
        border-radius: 4px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .activeLink {
      font-weight: 500;
      border-color: var(--border-main-color);
    }

    .disabledLink {
      svg {
        fill-opacity: .4;
      }
    }
  }

  .itemsCount {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.tableCardsWrapper {
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1 1 auto;
  flex-direction: column;
  height: 0;
  min-height: 150px;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

[dark-theme] {
  .wrapper {
    background-color: var(--dark-theme-background-dark-color);

    &.shortTableWrapper {
      color: var(--dark-theme-font-main-color);
    }
  }

  .createButton {
    background-color: var(--dark-theme-background-accent-color);
  }

  .tableWrapper,
  .tablePaginationWrapper {
    background-color: var(--dark-theme-background-dark-color);
  }

  .tableWrapper {
    .tableHeaderWrapper {
      background-color: var(--dark-theme-graphite-main-color);

      th {
        &.sortable {
          .sortIconsWrapper {
            &:hover {
              background-color: var(--dark-theme-background-dark-color);
            }
          }
        }
      }
    }

    .tableBodyWrapper {
      .emptyTableWrapper {
        background-color: var(--dark-theme-background-dark-color);
      }

      tr {
        border-color: var(--dark-theme-border-dark-color);
      }

      td {
        .clickable {
          color: var(--dark-theme-font-active-color);
        }
      }
    }
  }

  .emptyTableText {
    color: var(--dark-theme-font-disabled-color);

    & > a {
      color: var(--dark-theme-font-active-color);
    }
  }

  .tablePaginationWrapper {
    color: var(--dark-theme-font-disabled-color);
    border-top-color: var(--dark-theme-border-dark-color);

    .paginationContainer {
      li {
        a {
          color: var(--dark-theme-font-main-color);
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  %tableCellStyles {
    &:first-of-type {
      width: 20%;
    }
  }

  .createButton {
    padding: 10px;
  }

  .wrapper {
    .tableWrapper {
      width: 100%;
    }

    .emptyTableWrapper {
      flex: auto;
    }
  }

  .tablePaginationWrapper {
    padding: 15px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    height: auto;

    .tableWrapper {
      width: auto;
    }
  }

  .tableWrapper {
    display: flex;
    margin-top: 0;

    .tableHeaderWrapper {
      min-width: 130px;
      border-radius: 0 0 0 8px;

      tr {
        width: 100%;

        th {
          width: 100% !important;
        }
      }
    }

    .tableBodyWrapper {
      display: flex;
      overflow-y: hidden;
      width: 100%;
      padding: 0;
      white-space: nowrap;

      td {
        width: 100%;
        min-width: 175px;

        span {
          overflow: hidden;
        }

        &:last-of-type {
          justify-content: flex-start;

          span {
            margin: 0;

            svg {
              cursor: pointer;
            }
          }
        }
      }

      tr:not(:last-child) {
        border-bottom: transparent;
      }

      tr:last-child {
        td {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    tr {
      display: block;
      float: left;
    }

    th {
      &.sortable {
        &:after {
          display: none;
        }
      }
    }

    th,
    td {
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
      padding: 20px 15px;
      white-space: nowrap;

      &:first-of-type {
        overflow: visible;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-light-color);
      }

      &:last-of-type {
        width: 100%;

        span {
          svg {
            vertical-align: middle;
          }
        }
      }

      div {
        display: inline;
      }
    }
  }

  .tablePaginationWrapper {
    flex-wrap: wrap;

    gap: 5px;

    .paginationContainer {
      flex: 0 1 100%;
      justify-content: center;
      order: -1;
    }
  }

  [dark-theme] {
    .tableWrapper {
      td,
      th {
        &:not(:last-child) {
          border-bottom: 1px solid var(--dark-theme-border-dark-color);
        }
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .scrollableTable {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;

    .scrollableTableHeaderWrapper {
      width: 100%;

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    }

    .scrollableTableBodyWrapper {
      overflow-x: hidden;
      overflow-y: scroll;
      flex: 1 1 auto;
      height: 0;
      min-height: 150px;

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    }
  }

  .shortTableWrapper {
    .shortTableHeaderWrapper,
    .shortTableBodyWrapper tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .shortTableBodyWrapper {
      display: block;
      overflow-x: hidden;
      overflow-y: scroll;
      height: 165px;

      .emptyTableWrapper {
        height: 100%;

        svg {
          width: 200px;
          height: 100px;
        }
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }
}

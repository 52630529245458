.whatDoesItMean .parameters {
  display: flex;
  flex-wrap: wrap;

  gap: 20px;
  & > .parameter {
    width: calc(50% - 20px);
  }
}

.parameter {
  display: flex;
}

.parameterDot {
  width: 15px;
  height: 15px;
  margin: 2.5px 5px 0 0;
  border-radius: 50%;
}

.parameterName {
  font-size: 14px;
  font-weight: 500;
}

.title {
  font-size: 16px;
  margin: 0;
}

.whatDoesItMean .text {
  flex: 1 1;
}

@import "src/screenSizes";

.reviewDataModal {
  display: flex;
  flex-direction: column;
  width: 870px;
  max-width: 100%;
  min-height: 300px;
}

.searchSelect {
  margin-bottom: 25px;
  padding: 0 10px;

  .searchName {
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;
  }
}

.itemsWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;

  gap: 25px;
}

.item {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 150px;
  height: 150px;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  background-color: var(--white-dark-color);

  gap: 15px;
  svg {
    width: 100%;
    height: 100%;
  }
}

.error {
  font-size: 26px;
  margin: auto;
  padding: 0 30px;
  text-align: center;
}

[dark-theme] {
  .item {
    background-color: var(--dark-theme-background-light-color);
  }
}

@media screen and (max-width: $mobileScreen) {
  .error {
    font-size: 18px;
    padding: 0 15px;
  }
}

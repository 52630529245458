.esgIndexPieChart {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.charts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  column-gap: 16px;
  row-gap: 40px;
  padding-bottom: 24px;
}

.chartArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartWrapperOuter {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100%;
}

.chartWrapperInner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.label {
  position: absolute;
  bottom: -24px;
  font-size: 16px;
}

.preloader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &_global {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 15;
  }
  &_inner {
    height: 100%;
  }
}

.robustnessPreview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.buttons {
  display: flex;
  align-self: flex-end;
  padding: 8px 8px 0;
}

.button {
  margin-left: 8px;
}

.chartWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 8px;
}

.dataSourceScoreCellChart {
  margin: auto;
}

.cellChartsWrapper {
  display: grid;
  width: 100%;

  grid-template-columns: repeat(4, 1fr);
  gap: 7px;
}

@import "./buttonsStyle.module.scss";

.widgetTitle {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 40px;
  flex: 1 1;
  overflow: hidden;
  h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  gap: 15px;
}

.texts {
  display: flex;
  align-items: center;
  width: 100%;

  gap: 15px;
}

.dropDown {
  z-index: 12;
  & div {
    min-width: 175px;
    & > button {
      & > svg {
        width: 20px;
        height: 20px;
      }
      & > span {
        font-size: 14px;
      }
    }
  }
}

.datesBar {
  font-size: 14px;
  margin-left: auto;
  color: var(--font-secondary-color);
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;

  svg {
    cursor: pointer;
  }
}

.passwordReset {
  width: 100%;
  max-width: 568px;
  padding: 24px;
  text-align: center;
}

.logo {
  height: 100px;
}

.title {
  margin-top: 16px;
  font-size: 18px;
}

.note {
  margin-top: 8px;
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
}

.input {
  margin-top: 16px;
}

.button {
  margin-top: 16px;
  width: 100%;
  height: 40px;
  color: var(--white-dark-color);
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  // background-color: rgba(49, 88, 69, 1);
  background-color: var(--success-main-color);
}

.link {
  display: inline-block;
  margin-top: 16px;
  font-size: 11px;
  text-align: center;
  opacity: 0.75;
}

@media (min-width: 768px) {
  .logo {
    height: 174px;
  }

  .title {
    margin-top: 32px;
    font-size: 32px;
  }

  .note {
    font-size: 14px;
  }

  .input {
    margin-top: 24px;
  }

  .button {
    margin-top: 24px;
  }

  .link {
    margin-top: 24px;
  }
}

.timePlotterPlaceholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.timePlotterPlaceholderIcon {
  margin-right: 30px;
}

.timePlotterPlaceholderText {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  width: 100%;
  padding: 5px 10px;
  text-align: center;
  color: var(--font-secondary-color);
}

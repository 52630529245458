@import "src/screenSizes";

.aboutThis {
  width: 100%;
  margin-top: 20px;
  .dropDownButton svg {
    transform: rotate(180deg);
  }
}

.aboutThisOpened {
  .dropDownButton svg {
    transform: rotate(0deg);
  }
}

.dropDownButton {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  cursor: pointer;
}

.aboutThisContent {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  div > p, span{
    margin-top: 0;
    font-size: 14px;
  }
  div > p {
    margin-bottom: 5px;
    font-weight: 500;
  }
}

.title{
  font-size: 16px;
  margin: 0;
}


@media screen and (max-width: $tabletScreen) {
  .whatDoesItMean .parameters {
    flex-direction: column;
    & > .parameter{
      width: 100%;
    }
  }
}
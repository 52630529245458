.timePeriodSelector {
  position: relative;
  width: 100%;
  max-width: 310px;
}

.title {
  font-size: 14px;
  margin-bottom: 0;
  position: absolute;
  top: -5px;
  margin-top: 0;
  transform: translateY(-100%);
}

.widgetSummary {
  display: flex;
  flex-direction: column;
  height: 100%;

  gap: 15px;

  .chartWrapper {
    padding: 0;

    & > div {
      cursor: default;
    }
  }
}

.descriptionWrapper {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &:not(only-child) {
    & > span:first-child {
      &:after {
        content: " ";
      }
    }
  }

  &:empty {
    display: none;
  }
}

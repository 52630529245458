@import "src/screenSizes";

$table-border: 1px solid rgba($color: #707070, $alpha: .25);

%absoluteTableCell {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  border-bottom: $table-border;
  background-color: var(--background-main-color);
}

.filter {
  position: relative;

  &Icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
  }
}

.filtersWrapper {
  display: grid;
  margin-bottom: 16px;

  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 16px;
}

.input {
  input {
    padding-left: 40px;
  }
}

.main {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-height: 425px;
  border-radius: 4px;
  background-color: var(--background-main-color);
  box-shadow: 0 3px 6px #00000029;
}

.preloader {
  height: 100%;
  margin: auto;
}

.message {
  font-size: 11px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  opacity: .5;
}

.tableWrapper {
  font-size: 12px;
  overflow: auto;
  flex: 1;
  text-align: left;

  table {
    width: 100%;
    border-collapse: collapse;

    .rowSelection,
    .rowDeletion {
      font-size: 0;
      width: 1px;
    }

    &.leftAlignedTable {
      tr {
        th:nth-child(1),
        td:nth-child(1) {
          width: auto;

          @extend %absoluteTableCell;
        }

        th:nth-child(2),
        td:nth-child(2) {
          left: 45px;
          overflow: hidden;
          width: 175px;
          white-space: nowrap;
          text-overflow: ellipsis;

          @extend %absoluteTableCell;
        }

        th:nth-child(3),
        td:nth-child(3) {
          padding-left: 225px;
        }
      }

      th {
        &:last-child {
          width: 100%;
        }
      }
    }
  }

  tr {
    border-bottom: $table-border;
  }

  th {
    position: sticky;
    top: 0;
    height: 40px;
  }

  td {
    height: 32px;
    vertical-align: middle;

    &:not(:first-child) {
      min-width: 100px;
    }
  }

  th,
  td {
    padding: 0 16px;
    white-space: nowrap;
    &.expandableCell {
      overflow: hidden;
      max-width: 1px;
      text-overflow: ellipsis;
    }
    &.isNoWrap {
      white-space: initial;
    }
  }
}

.sortingIcon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  &Down {
    transform: translateY(-50%) rotate(180deg);
  }
}

.trashIcon {
  &:hover {
    color: var(--pink-main-color);
  }
}

.trashIcon,
.checkboxIcon {
  cursor: pointer;
}

.clockedCheckboxIcon {
  cursor: not-allowed;
}

.warning {
  line-height: 32px;
  padding: 0 16px;
  text-align: center;
  color: var(--pink-main-color);
}

.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
}

.pagination {
  display: flex;
  padding: 0;
  li {
    font-size: 0;
    display: inline-block;

    &:not(:first-child) {
      margin-left: 8px;
    }

    a {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: all .2s;
      border: 1px solid rgba($color: #707070, $alpha: .25);
      border-radius: 6px;

      &:hover {
        text-decoration: none;
        border: 1px solid rgba($color: #707070, $alpha: .5);
      }
    }
  }
}

.activeLink {
  border: 1px solid rgba($color: #707070, $alpha: 1) !important;
}

.loadMoreButtonWrapper {
  display: flex;
  justify-content: center;
  button {
    margin-bottom: 15px;
    padding: 5px 15px;
    color: white;
    border-radius: 5px;
    background: var(--success-main-color);
  }
}

.selectWrapper {
  width: 150px;
  height: 25px;
  margin: 7px 0 7px auto;
  & > div {
    font-size: 12px;
    &:nth-child(1) {
      // select bar
      height: 25px;
      & > div {
        &:nth-child(1) {
          // input wrapper
          line-height: 25px;
        }
        &:nth-child(2) {
          line-height: 25px;
          &:nth-child(1) {
            width: calc(100% - 29px);
          }
          &:nth-child(2) {
            // arrow
            width: 25px;
          }
        }
      }
    }
    &:nth-child(2) {
      // dropdown menu
      & > div {
        // options list wrapper
        & > div {
          line-height: 25px;
          // option
          height: 25px;
        }
      }
    }
  }
  div[class~="popupDown"] {
    top: 29px;
  }
  div[class~="popupUp"] {
    bottom: 29px;
  }
}

[dark-theme] {
  %absoluteTableCell {
    background-color: var(--dark-theme-background-dark-color);
  }

  .main {
    background-color: #242b4384;
    box-shadow: none;
  }
}

@media (max-width: $mobileScreen) {
  .filter {
    &Icon {
      top: 10px;
    }
  }

  .filtersWrapper {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
}

@import "src/screenSizes";

%option {
  line-height: 40px;
  overflow: hidden;
  height: 40px;
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .75;
}

.subregionsMultiSelect {
  font-size: 14px;
  position: relative;
  height: 40px;

  &:focus-within > .input {
    border-color: var(--border-main-color);
  }
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  background-color: var(--background-main-color);
}

.errorMessage {
  padding-left: 15px;
  color: var(--pink-main-color);
}

.label,
.placeholder {
  line-height: 40px;
  overflow: hidden;
  width: calc(100% - 44px);
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.placeholder {
  color: var(--font-disabled-color);
}

.arrowIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;

  & > * {
    transition: all .2s;
  }
}

.popup {
  position: absolute;
  z-index: 3;
  right: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 320px;
  border-radius: 4px;
  background-color: var(--background-main-color);
  box-shadow: 0 3px 6px var(--main-box-shadow-color);
  & > div {
    width: 50%;
  }
  &Up {
    bottom: 44px;
  }
  &Down {
    top: 44px;
  }
}

.filterInput {
  font-size: 14px;
  font-weight: inherit;
  width: calc(100% - 16px);
  height: 32px;
  margin: 8px;
  padding: 0 8px;
  color: inherit;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .1);
  &::placeholder {
    opacity: .5;
    color: inherit;
  }
}

.options {
  overflow-y: auto;
  max-height: calc(100% - 56px);
  & > div {
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .025);
  }
  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 0;
    background-color: rgba(0, 0, 0, .1);
  }
}

.option {
  width: 100%;
  transition: all .2s;
  text-align: left;

  @extend %option;
  &:hover {
    opacity: 1;
  }
}

.option.allSelected {
  display: flex;
  align-items: center;
  cursor: default;
  opacity: 1;
  & > input {
    margin: 0;
    margin-right: 5px;
  }
}

.popupOptions {
  padding-right: 7px;
}

.popupSelectedOptions {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  border-left: 1px solid #a5a5a5;
  .wrapper {
    overflow: auto;
    height: calc(100% - 68px);
    padding: 0 7px;
    & > div:not(:last-child) {
      margin-bottom: 7px;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, .025);
    }
    &::-webkit-scrollbar-thumb {
      border: none;
      border-radius: 0;
      background-color: rgba(0, 0, 0, .1);
    }
  }
}

.doneBtn {
  font-size: 12px;
  position: relative;
  justify-content: center;
  width: calc(100% - 14px);
  max-width: 150px;
  height: 40px;
  margin: 14px auto;
  transform: translateY(0);
  letter-spacing: .32px;
  color: #f3f5f4;
  border-radius: 4px;
  background-color: var(--success-main-color)
}

.selectedOption {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 7px;
  cursor: pointer;
  border: 1px solid #707070;
  border-radius: 7px;
  &:not(:last-child) {
    margin-bottom: 7px;
  }
  & > span {
    padding: 0;

    @extend %option;
  }
  svg {
    min-width: 15px;
    margin-right: 7px;
  }
}

.noOptions {
  @extend %option;
}

[dark-theme] {
  .subregionsMultiSelect:focus-within > .input {
    border-color: var(--dark-theme-border-main-color);
  }

  .input {
    border-color: var(--dark-theme-border-dark-color);
    background-color: var(--dark-theme-background-dark-color);
  }

  .placeholder {
    color: var(--dark-theme-font-disabled-color);
  }

  .popup {
    background-color: var(--dark-theme-background-dark-color);
  }
}

.attitudeLineChartWithLabels {
  position: relative;
  width: 100%;
  height: 100%;
}

.filters {
  position: absolute;
  z-index: 5;
  top: 150px;
  left: 56px;
  display: flex;
  justify-content: center;
}

.filter {
  font-size: 10px;
  line-height: 32px;
  width: 72px;
  height: 32px;
  margin-right: 16px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  opacity: .5;
  color: currentColor;
  border: 1px solid;
  border-radius: 6px;
  &Active {
    opacity: 1;
  }
}

.attitudeLineChartWithLabelsPreview {
  .filters {
    top: 80px;
    left: 40px;
  }

  .filter {
    font-size: 7px;
    line-height: 20px;
    width: 50px;
    height: 20px;
    &Active {
      opacity: 1;
    }
  }
}

.attitudePieChart {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.labels {
  display: flex;
  justify-content: center;
  height: 24px;
}

.label {
  font-size: 10px;
  display: flex;
  align-items: center;
  color: currentColor;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.labelIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
}

.chartsWrapper {
  display: grid;
  align-items: center;
  flex: 1;
  margin: 16px 0 24px;

  grid-gap: 30px;
  &For1 {
    grid-template: "chart0" auto/ auto;
  }
  &For3 {
    grid-template: "chart0 chart1" auto
    "chart0 chart2" auto / auto auto;
  }
  &For5 {
    grid-template: "chart0 chart1 chart3" auto
    "chart0 chart2 chart4" auto / auto auto auto;
  }
  &MoreThan5 {
    overflow: auto;
    max-width: 100%;
    & > div {
      margin: auto;
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }
}

.brandName {
  font-size: 14px;
  overflow: hidden;
  max-width: 130%;
  height: 20px;
  margin-top: 4px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: currentColor;
}

.attitudePieChartPage {
  .chartsWrapper {
    display: grid;
    flex: 1;
    margin: 16px 0 24px;

    grid-gap: 40px;
    .chartWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      &:nth-child(1) {
        width: 275px !important;
        height: 275px !important;
      }
      &:not(:nth-child(1)) {
        width: 107px !important;
        height: 107px !important;
      }
    }

    &For1 {
      grid-template: "chart0" auto/ auto;
    }
    &For3 {
      grid-template: "chart0 chart0" auto
      "chart1 chart2" auto / auto auto;
    }
    &For5 {
      grid-template: "chart0 chart0" auto
      "chart1 chart2" auto
      "chart3 chart4" auto / auto auto;
    }
  }
}

@media screen and (max-width: 1024px) {
  .attitudePieChartPage {
    .chartsWrapper {
      &MoreThan5 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & > div {
          &:first-child {
            flex: 1 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 767.99px) {
  .attitudePieChartPage {
    .chartWrapper {
      &:nth-child(1) {
        width: 296px !important;
        height: 296px !important;
      }
      &:not(:nth-child(1)) {
        width: 128px !important;
        height: 128px !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .attitudePieChartPage {
    .chartsWrapper {
      &For3 {
        grid-template: "chart0 chart1" auto
        "chart0 chart2" auto / auto auto;
      }
      &For5 {
        grid-template: "chart0 chart0 chart1" auto
        "chart0 chart0 chart2" auto
        "chart3 chart4 placeholder" auto / auto auto auto;
      }
    }
  }
}

@media (min-width: 992px) {
  .attitudePieChartPage {
    .chartsWrapper {
      &For5 {
        grid-template: "chart0 chart1 chart3" auto
        "chart0 chart2 chart4" auto / auto auto auto;
      }
    }
  }
}

$arrowDesktopSize: 35px;

.disabled {
  pointer-events: none;
  opacity: .4;
}

.arrow {
  width: $arrowDesktopSize;
  height: $arrowDesktopSize;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid;
  background-color: var(--background-main-color);
  border-color: var(--white-main-color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

[dark-theme] {
  .arrow {
    background-color: var(--graphite-dark-color);
    box-shadow: transparent;
  }
}

.esgSentimentPieChart {
  display: grid;
  width: 100%;
  margin-top: 24px;
  padding-bottom: 24px;

  grid-gap: 64px 32px;
  grid-template: "pie1 pie2" max-content
  "pie3 pie0" max-content / 1fr 1fr;
}

.chartArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartWrapperOuter {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 100%;
}

.chartWrapperInner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.label {
  font-size: 16px;
  position: absolute;
  bottom: -24px;
}

@media (min-width: 992px) {
  .esgSentimentPieChart {
    grid-template: "pie1 pie2 pie3" max-content
    "blank1 pie0 blank2" max-content / 1fr 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .esgSentimentPieChart {
    grid-template: "pie0 pie1 pie2 pie3" 100% / 1fr 1fr 1fr 1fr;
  }
}

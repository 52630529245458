.button {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 11px 24px;
  border: 1px solid var(--graphite-dark-color);
  border-radius: 4px;

  gap: 4px;

  &:hover {
    border-color: var(--graphite-dark-color);
    background-color: var(--white-dark-color);
    box-shadow: 2px 2px 6px rgba(31, 31, 31, .25);
  }

  &:active {
    color: var(--font-main-color);
    border-color: var(--graphite-dark-color);
    background-color: var(--border-light-color);
  }

  &:disabled {
    background-color: transparent;
    color: var(--font-disabled-color);
    border-color: var(--button-background-disabled-color);
    box-shadow: none;

    & > svg {
      fill: var(--font-disabled-color);
    }
  }

  & > svg {
    width: 24px;
  }
}

[dark-theme] {
  .button {
    border: 1px solid var(--white-main-color);

    &:hover {
      border-color: var(--dark-theme-border-main-color);
      background-color: var(--dark-theme-background-light-color);
      box-shadow: 2px 2px 6px rgba(31, 31, 31, .25);
    }

    &:active {
      color: var(--dark-theme-font-main-color);
      border-color: var(--dark-theme-border-main-color);
      background-color: #37446f;
    }

    &:disabled {
      color: var(--dark-theme-border-dark-color);
      border-color: var(--dark-theme-border-dark-color);
      background-color: var(--dark-theme-background-dark-color);

      & > svg {
        fill: var(--dark-theme-font-disabled-color);
      }
    }
  }
}

$tooltipLineHeight: 16px;

.withTooltip {
  position: relative;

  & > * {
    cursor: pointer;
  }

  &:hover:after {
    font-size: 12px;
    font-weight: 400;
    line-height: $tooltipLineHeight;
    position: absolute;
    overflow: hidden;
    width: max-content;
    max-width: 125px;
    padding: 5px;
    content: attr(data-tooltip);
    text-align: center;
    color: var(--font-light-color);
    border-radius: 4px;
    background: rgba(36, 43, 67, .9);
    animation: smooth-appear 0.25s ease-in;
    white-space: initial;
  }

  &[data-tooltipposition="top"]:hover:after {
    top: -5px;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  &[data-tooltipposition="right"]:hover:after {
    top: 50%;
    right: -5px;
    transform: translate(100%, -50%);
  }

  &[data-tooltipposition="bottom"]:hover:after {
    bottom: -5px;
    left: 50%;
    transform: translate(-50%, 100%);
  }

  &[data-tooltipposition="left"]:hover:after {
    top: 50%;
    left: -5px;
    transform: translate(-100%, -50%);
  }
}

@keyframes smooth-appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

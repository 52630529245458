.lineChart {
  display: grid;

  grid-template-rows: min-content auto;
}

.head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.timePlotterLabel {
  font-size: 12px;
}

.timePlotterArea {
  cursor: pointer;
  opacity: .7;
}

.timePlotterAreaActive,
.timePlotterArea:hover,
.timePlotterLabel:hover {
  opacity: 1;
}

.timePlotterLabelName {
  text {
    opacity: 0;
  }
  &:hover {
    opacity: 1;
    text {
      opacity: 1;
    }
  }
}

.chartWrapperOuter {
  display: flex;
  flex-direction: column;
}

.legendAndTogglerWrapper {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: space-between;

  .togglerWrapperRightSide {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;

    div:only-child {
      margin-left: auto;
    }
  }
}

.timePlotterToggler {
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  & > span {
    margin-right: 8px;
  }
}

.timePlotterModalWrapper {
  margin-left: 0;
}

.yAxisLegend {
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  align-self: flex-start;
  margin-right: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .legendAndTogglerWrapper {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    .togglerWrapperRightSide {
      width: auto;
      margin: 0;
    }
  }

  .yAxisLegend {
    align-self: flex-end;
    margin-right: auto;
  }

  .timePlotterToggler {
    align-self: flex-end;
    flex-shrink: 0;
    justify-content: center;
    width: 150px;
    margin: 4px 0 4px auto;
  }

  .timePlotterModalWrapper {
    margin-left: 15px;
  }
}

.switchText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.chartWrapper {
  display: flex;
  flex: 1;
  padding: 18px 0;
}

.ticks {
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-right: 8px;
  opacity: .75;
  & > div {
    font-size: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
  }
}

.chartWrapperInner {
  position: relative;
  display: flex;
  flex: 1;
}

.backgroundWrapper {
  position: relative;
  height: 100%;
  transition: flex .2s;
}

.title {
  position: absolute;
  top: -16px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 16px;

  &Shifted {
    top: -32px;
  }

  & > * {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  &Line {
    flex: 1;
    height: 1px;
    background-color: var(--gold-main-color);
  }

  &Text {
    font-size: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--gold-main-color);
  }
}

.background {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > div {
    flex: 1;
    margin: .5px 0;
  }
}

.dividerVertical {
  position: relative;
  height: 100%;

  &Shifted {
    height: calc(100% + 16px);
    margin-top: -16px;
  }

  &::before {
    position: absolute;
    top: -13px;
    left: -5px;
    width: 11px;
    height: 11px;
    content: "";
    transform: rotate(45deg);
    background-color: #bcbcbc;
  }

  &::after {
    position: absolute;
    top: 0;
    left: -.5px;
    width: 1px;
    height: 100%;
    content: "";
    background-color: #bcbcbc;
  }
}

.plotter {
  position: absolute;
  top: -30px;
  height: calc(100% + 30px);
  margin-left: 35px;
  padding: 0 1px;
  padding-right: 35px;
  cursor: pointer;
  transition: left .2s, width .2s;
  opacity: .75;

  &Core {
    display: flex;
    flex-direction: column;
    height: 100%;

    &Title {
      font-size: 9px;
      line-height: 16px;
      position: relative;
      overflow: hidden;
      height: 16px;
      padding: 0 4px;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;

      &::before {
        position: absolute;
        top: 5px;
        left: 0;
        display: none;
        content: "";
        border-width: 3px;
        border-style: solid;
        border-color: transparent transparent transparent #707070;
      }

      &::after {
        position: absolute;
        top: 5px;
        right: 0;
        display: none;
        content: "";
        border-width: 3px;
        border-style: solid;
        border-color: transparent #707070 transparent transparent;
      }
    }

    &Background {
      flex: 1;
      transition: background-color .2s;
    }
  }

  .plotterCoreTitle {
    font-size: 12px;
    bottom: 10px;
    left: -50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 120px;
    height: 30px;
  }

  .plotterDataWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .plotterData {
    font-size: 10px;
    visibility: hidden;
  }

  &:hover {
    z-index: 5 !important;
    opacity: 1;


    .plotterCoreTitle::before,
    .plotterCoreTitle::after {
      display: block;
    }

    .plotterCoreBackground {
      opacity: .6;
    }

    .plotterData {
      visibility: visible;
    }
  }

  .plotterCoreBackground {
    min-width: 5px;
    opacity: .4;
  }
}

.chart {
  position: absolute;
  z-index: 4;
  top: -5px;
  right: -5px;
  bottom: -35px;
  left: -5px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .head {
    flex-direction: column-reverse;
  }
  .widgetButtonsBar {
    margin-bottom: 10px;
    margin-left: auto;
  }
}

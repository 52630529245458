.radarChart {
  display: grid;
  grid-template-rows: min-content auto;
}

.labels {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.label {
  max-width: 264px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & > div {
    &:nth-child(1) {
      flex-shrink: 0;
      margin-right: 4px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
    &:nth-child(2) {
      font-size: 10px;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &Inactive {
    opacity: 0.25;
  }
}

.chartWrapperOuter {
  padding: 36px 96px 24px;
}

.chartWrapper {
  position: relative;
}

.chartWrapperInner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.legendWrapper {
  position: absolute;
  width: 96px;
  font-size: 10px;
  z-index: 1;
  .legend {
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .words {
    position: absolute;
    top: 24px;
    display: none;
    width: 100%;
    padding: 0 8px;
    z-index: 1;

    .word {
      height: 24px;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:not(:first-child) {
        border-top: 1px solid rgba(#707070, 0.25);
      }
    }
  }
  &:hover {
    .words {
      background-color: var(--white-dark-color);
      box-shadow: 0px 3px 6px #00000029;
      display: block;
    }
  }
}

[dark-theme] {
  .legendWrapper {
    &:hover {
      .words {
        background-color: var(--dark-theme-background-light-color);
      }
    }
  }
}

@import "../../../../screenSizes.scss";

.actionsOptionsWrapper {
  display: flex;
  align-items: center;

  gap: 15px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

.desktopIcons {
  display: flex;

  gap: 15px;
  & > button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;

    &:disabled > svg {
      opacity: .5;
    }

    &:after {
      position: absolute;
      bottom: -5px;
      left: 50%;
      display: none;
      padding: 5px 10px;
      content: attr(data-text);
      transform: translate(-50%, 100%);
      white-space: nowrap;
      color: var(--font-light-color);
      border-radius: 4px;
      background: rgba(36, 43, 67, .9);
    }

    &:hover {
      background-color: var(--white-dark-color);
      &::after {
        display: flex;
      }
    }
  }
}

.actionsOptions {
  position: relative;
}

.actionsOptionsOpened {
  .dots {
    transform: rotate(0deg);
  }
}

.actionsOptions.hiddenOnDesktop {
  display: none;
}

.actionsOptionsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  gap: 7.5px;

  &:hover {
    background-color: var(--white-dark-color);
  }
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  transition: transform .2s;
  transform: rotate(90deg);
  transform-origin: center;

  gap: 3px;
  & > span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--graphite-dark-color);
  }
}

.dropDown {
  position: absolute;
  right: 0;
  bottom: -5px;
  min-width: 200px;
  width: max-content;
  max-width: 250px;
  margin: 0;
  padding: 0;
  transform: translateY(100%);
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  background-color: var(--background-main-color);
  box-shadow: 0 0 24px rgba(31, 31, 31, .05);
}

.option {
  list-style-type: none;

  & > button {
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;

    gap: 7px;

    &:disabled {
      opacity: .5;
    }

    & > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & > svg {
      flex: 0 0 auto;
    }
  }
}

.option.hiddenOnDesktop {
  display: none;
}

@media screen and (max-width: $tabletScreen) {
  .actionsOptions.hiddenOnDesktop {
    display: flex;
  }

  .desktopIcons {
    display: none;
  }

  .option.hiddenOnDesktop {
    display: list-item;
  }
}

[dark-theme] {
  .dropDown {
    border: 1px solid var(--dark-theme-background-dark-color);
    background-color: var(--dark-theme-graphite-main-color);
  }

  .desktopIcons > button:hover {
    background-color: var(--dark-theme-background-light-color);
  }

  .actionsOptionsButton {
    &:hover {
      background-color: var(--dark-theme-background-light-color);
    }
  }

  .actionsOptionsOpened {
    .actionsOptionsButton {
      background-color: var(--dark-theme-background-light-color);
    }
  }

  .moreOptionsOpened .moreOptionsButton {
    background-color: var(--dark-theme-background-dark-color);
  }

  .dots > span {
    background-color: var(--background-main-color);
  }
}

@import "src/screenSizes";

.reputationReviewData {
  display: flex;
  min-height: 575px;
  padding-top: 20px;
}

.reviewData {
  position: static;
  & > button {
    top: 8px;
    left: 12px;
    transform: initial;
  }
}

.excludeButton:disabled {
  background: var(--button-background-disabled-color);
}

.label,
.excludeButton:disabled {
  color: var(--font-disabled-color);
}

.filter {
  position: relative;
  margin: 15px 0;
  svg {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
  }
  .input {
    max-width: 600px;
    & > input {
      padding-left: 50px;
    }
  }
}

.sources {
  overflow: auto;
  height: 160px;
  margin-bottom: 40px;
  padding: 15px;
  border: 1px solid #bcbec5;
  border-radius: 5px;
}

.sourcesItem {
  display: inline-flex;
  margin-bottom: 10px;
  color: white;
  background-color: #bb7;
  &:not(:last-child) {
    margin-right: 10px;
  }
  & > button {
    display: block;
  }
}

.parameter {
  height: 28px;
  margin: 0 10px 10px 0;
  padding: 0 16px;
  transition: opacity .2s;
  opacity: .5;
  border: 1px solid transparent;
  border-radius: 6px;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    opacity: 1;
  }
  &Active {
    opacity: 1;
    border-color: #8e8e63;
  }
}

.searchTable {
  & > div {
    overflow: initial;
    & > div:nth-child(1) {
      overflow: initial;
    }
  }
}

.excluded {
  display: flex;
  align-items: flex-end;
  margin-top: 40px;

  gap: 40px;
  .label {
    font-size: 14px;
    width: 100%;
    margin: 0 0 5px 0;
    letter-spacing: .7px;
  }
}

.excludedWrapper {
  display: grid;
  flex: 1 1;

  grid-template-columns: 65% 35%;
  & > div {
    overflow-y: auto;
    height: 200px;
    padding: 15px;
    border: 1px solid #bcbec5;
  }
}

.excludedItem {
  display: inline-flex;
  margin-bottom: 10px;
  border: 1px solid black;
  background-color: transparent;
  & > button {
    display: block;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
  &:hover {
    color: black;
    background-color: transparent;
    & > button {
      color: black;
    }
  }
}

.excludeButton {
  font-size: 20px;
  line-height: 24px;
  margin-left: auto;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  background: var(--success-main-color);
}

.innerModal {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.innerModalWrapper {
  position: absolute;
  top: 35%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -50%);
  background-color: var(--white-dark-color);
  p {
    font-size: 20px;
    text-align: center;
  }
}

.switchWarningButtons,
.parametersButtons {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  button {
    min-width: 100px;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    &:nth-child(1) {
      margin-right: 15px;
      background-color: var(--pink-main-color);
    }
    &:nth-child(2) {
      background: var(--success-main-color);
    }
  }
}

.parametersButtons button:nth-child(1) {
  margin-right: 0;
  background: var(--success-main-color);
}

.loader {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
}

.error {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  width: 600px;
  max-width: 100%;
  height: 200px;
  margin: auto;
  p {
    font-size: 24px;
    width: calc(100% - 200px);
    text-align: center;
  }
}

.changedParametersTip {
  font-size: 13px;
}

.boldLabel {
  font-weight: 500;
}

[dark-theme] {
  .loader {
    background-color: var(--dark-theme-background-dark-color);
  }

  .innerModalWrapper {
    background-color: var(--dark-theme-background-dark-color);
  }

  .label,
  .excludeButton:disabled {
    color: var(--dark-theme-font-disabled-color);
  }

}

@media screen and (max-width: $tabletScreen) {
  .error {
    p {
      font-size: 16px;
      width: calc(100% - 100px);
      text-align: center;
    }
    .image {
      position: absolute;
      top: -25px;
      right: -100px;
      width: 300px;
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .esgReviewData {
    min-height: initial;
  }
}

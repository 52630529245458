$borderStyle: 1px solid rgba($color: #707070, $alpha: .75);

.namesList {
  position: relative;
  ul {
    display: flex;
    overflow-y: auto;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    height: 40px;
    margin: 0;
    padding: 3px 4px;
    border: $borderStyle;
    border-radius: 4px;

    gap: 6px;

    &::-webkit-scrollbar-track {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  & > button {
    position: absolute;
    top: 50%;
    right: 10px;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    color: currentColor;
    svg {
      margin-right: 10px;
    }
  }
}

.namesListItem {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  padding: 0 8px;
  list-style-type: none;
  border: $borderStyle;
  border-radius: 4px;
  span {
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    margin-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: currentColor;
  }
  button {
    height: 16px;
  }
}

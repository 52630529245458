@import "src/screenSizes";
@import "../../generalStyles/generalWidgetStyles.module.scss";

.description {
  margin-top: 25px;
  .main {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .additional {
    font-size: 14px;
    opacity: 0.8;
  }
}

.widgetWrapperOuter {
  width: 100%;
  margin: auto;
  max-width: 1000px;
}

.cellChartsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  align-items: center;
  width: 100%;
}


@media screen and (max-width: $smallDesktop) {
  .cellChartsWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: $mobileScreen) {
  .cellChartsWrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

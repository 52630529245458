.cellChart {
  display: flex;
  flex-direction: column;
}

.labels {
  display: flex;
  flex-direction: column;
}

.labelWrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.value {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 500;
  color: #fdfdfd;
}

.label {
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 9px;
  font-weight: 700;
  color: #fdfdfd;
}

.cellsWrapperOuter {
  flex: 1;
}

.cellsWrapperInner {
  padding-top: 100%;
  position: relative;
}

.cellsOuter {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cells {
  display: grid;
  grid-gap: 4px;
  grid-template: repeat(10, auto) / repeat(10, auto);
  width: 100%;
  height: 100%;
}

@media (max-width: 767.99px) {
  .cellChart {
    max-width: 296px !important;
  }
  .labels {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .cellChart {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

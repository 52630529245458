.chartsButtonsWrapper {
  position: relative;
  z-index: 11;
  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;

  gap: 12px;
}

.chartsButton {
  opacity: .6;
  background-color: transparent;
  box-shadow: initial;
}

.chartsButtonActive {
  opacity: 1;
  background-color: var(--white-dark-color);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

[dark-theme] {
  .chartsButtonActive {
    background-color: var(--dark-theme-background-light-color);
  }
}

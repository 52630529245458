@import "src/screenSizes";

$sideBarWidth: 230px;
$iconSize: 20px;
$action-bar-height: 93px;

.widgetsSidebar {
  &Open {
    z-index: 2;
    right: 0;
    bottom: 0;
  }
}

.buttonPlaceholder {
  height: 40px;
}

.sideBarPreReleaseWrapper {
  position: absolute;
  top: 5px;
  right: 5px;
}

.button {
  font-weight: 500;
  position: relative;
  z-index: 3;
  height: 40px;
  & > * {
    vertical-align: middle;
    &:nth-child(2) {
      font-size: 14px;
      margin: 0 8px;
    }
    &:nth-child(3) {
      width: 8px;
      transition: all .2s;
    }
  }
}

.dropDown {
  position: fixed;
  z-index: 1001;
  top: calc(var(--actionBar-height) - 5px);
  right: 0;
  display: flex;
  min-width: 20px;
  background-color: var(--background-main-color);
}

.content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: $sideBarWidth;
  height: calc(100vh - var(--actionBar-height) + 5px);
  box-shadow: 0 6px 6px rgba(0, 0, 0, .16);
}

.selectWrapper {
  margin: 20px 25px 0 15px;
}

.tiles {
  display: flex;
  overflow-y: auto;
  flex: 1;
  flex-direction: column;
  padding: 15px;

  gap: 15px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .025);
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 0;
    background-color: rgba(0, 0, 0, .1);
  }
}

.tilesSection {
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 15px;
}

.tilesSectionTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  overflow: hidden;
  align-items: center;
  align-self: flex-start;
  flex: 1;
  width: 100%;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--font-disabled-color);

  gap: 10px;

  &:after {
    flex: 1;
    height: 1px;
    content: "";
    background-color: var(--border-light-color);
  }
}

.tile {
  position: relative;
  width: 140px;
  height: 140px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.tileContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.widgetIcon {
  width: 65px;
}

.widgetTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dragIcon {
  position: absolute;
  top: 50%;
  left: 5px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  opacity: .5;
}

.addWidgetIcon {
  position: absolute;
  top: 15px;
  left: 0;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  stroke-width: 0.1;
  padding: 5px;
}

.lockBar {
  font-size: 8px;
  position: absolute;
  top: 2px;
  right: 3px;
  left: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > div {
    margin-left: 4px;
    transition: all .2s;
  }

  & > svg {
    width: $iconSize;
    height: $iconSize;
  }
}

.tip {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-right: 0;
  text-align: center;
  color: var(--font-disabled-color);

  gap: 15px;

  & > svg {
    width: 120px;
    height: 75px;
  }
}

[dark-theme] {
  .dropDown {
    background-color: var(--dark-theme-graphite-main-color);
  }

  .tip {
    color: var(--dark-theme-border-main-color);
  }

  .tilesSectionTitle {
    color: var(--dark-theme-border-main-color);

    &:after {
      background-color: var(--dark-theme-border-dark-color);
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .dropDown {
    min-width: initial;
    min-height: initial;
  }
}

@import "../../generalStyles/generalWidgetStyles.module.scss";

.description {
  &Part1 {
    margin-top: 24px;
    font-size: 20px;
  }
  &Part2 {
    margin-top: 24px;
    font-size: 10px;
    text-align: center;
  }
  &Part3 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
  }
}

@import "/src/screenSizes.scss";

.nameModal {
  width: 370px;
  max-width: 100%;
}

.form {
  margin-top: 15px;
}

.inputWrapper {
  margin-bottom: 30px;
}

.label {
  font-weight: 500;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  gap: 5px;

  & > button {
    font-weight: 500;
    line-height: 20px;
    padding: 5px 10px;
  }

  & > button[type="submit"] {
    color: var(--font-light-color);
    border-radius: 4px;
    background-color: var(--success-main-color);
  }
}

@media screen and (max-width: $mobileScreen) {
  .nameModal {
    width: 100%;
  }
}

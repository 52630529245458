.switcherButton {
  display: flex;
  align-content: center;

  gap: 7px;
}

.toggleButton {
  position: relative;
  width: 40px;
  height: 20px;
  border: 1px solid black;
  border-radius: 25px;
}

.toggleButton .circle {
  position: absolute;
  top: 50%;
  left: calc(100% - 2px);
  width: 15px;
  height: 15px;
  transform: translate(-100%, -50%);
  border-radius: 50%;
  background-color: var(--pink-main-color);
}

.toggleButton.Active > .circle {
  left: 2px;
  transform: translateY(-50%);
}

.text {
  line-height: 20px;
}

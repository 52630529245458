.dashboardTitle {
  display: flex;
  align-items: center;
  overflow: hidden;

  font-size: 22px;
  line-height: 32px;
  font-weight: 500;

  & > svg {
    flex: 0 0 auto;
    height: 24px;
    width: 24px;
  }
}

.dashboardTitleName {
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.titleWrapper {
  margin-bottom: 2px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #707070;
}

.title {
  grid-area: title;
  color: var(--gold-main-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.articlesQuantity {
  grid-area: articlesQuantity;
  white-space: nowrap;
  & > span:last-child {
    display: none;
  }
}

.cellsOuterWrapper {
  position: relative;
}

.cellsInnerWrapper {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cells {
  display: grid;
  grid-gap: 2px;
  grid-template: repeat(10, auto) / repeat(10, auto);
  direction: rtl;
  width: 100%;
  height: 100%;
}

.cell {
  background-color: var(--white-dark-color);
  transition: background-color 1s ease;
}

@media (min-width: 768px) {
  .articlesQuantity {
    & > span:last-child {
      display: inline;
    }
  }
}

[dark-theme] {
  .cell {
    background-color: var(--dark-theme-background-light-color);
  }
}

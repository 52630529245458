@import "src/screenSizes";

.modalWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 14;
  overflow: auto;
}

.childrenWrapper {
  position: relative;
  margin: auto;
  padding: 15px;
  color: var(--graphite-dark-color);
  background-color: var(--background-main-color);
  border-radius: 8px;
  box-shadow: 0 0 24px rgba(31, 31, 31, 0.05);
  overflow: hidden;
  width: auto;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
}

.closeButtonSection {
  position: absolute;
  right: 20px;
  top: 30px;
  display: flex;
  width: 180px;
  height: 40px;
  align-items: center;
}

.closeButtonWithWrapper {
  z-index: 2;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #707070;
  width: 36px;
  height: 36px;
  background: var(--background-main-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

[dark-theme] {
  .childrenWrapper {
    color: #efe9ea;
    background-color: var(--dark-theme-background-dark-color);
  }
}

@media (max-width: $mobileScreen) {
  .childrenWrapper {
    width: 100%;
  }
}

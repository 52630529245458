.labels {
  display: flex;
  overflow: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-height: 48px;
}

.label {
  display: flex;
  align-items: center;
  max-width: 264px;
  padding: 4px 8px;
  cursor: pointer;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  & > div {
    &:nth-child(1) {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      border-radius: 50%;
    }
    &:nth-child(2) {
      font-size: 10px;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &Inactive {
    opacity: .25;
  }
}

%relatedWordsWrapperPaddings {
  padding: 10px 20px;
}

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tagCloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  margin: auto;
}

.tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding: 5px 10px;
  .value {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.widthRelatedWords {
  & > span {
    cursor: default;
  }
}

.relatedWordsWrapper {
  position: absolute;
  z-index: 12;
  bottom: 0;
  width: 260px;
  background-color: var(--background-main-color);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  .value {
    display: flex;
    overflow: hidden;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

    @extend %relatedWordsWrapperPaddings;
    span {
      font-size: 20px;
      overflow: hidden;
      flex: 1 1;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    svg {
      margin-right: 10px;
    }
  }

  .tip {
    font-size: 12px;
    color: var(--graphite-dark-color);

    @extend %relatedWordsWrapperPaddings;
  }

  &.left {
    right: 0;
  }

  &.right {
    left: 0;
  }

  &.top {
    transform: translateY(-20%);
  }

  &.bottom {
    transform: translateY(100%);
  }

  &.center.top {
    left: 50%;
    transform: translate(-50%, -20%);
  }
  &.center.bottom {
    left: 50%;
    transform: translate(-50%, 100%);
  }
}

.relatedWords {
  margin: 0;
  padding: 0;

  li {
    font-size: 16px;
    list-style-type: none;
    color: var(--graphite-dark-color);

    @extend %relatedWordsWrapperPaddings;
    &:nth-child(odd) {
      background-color: var(--white-dark-color);
    }
  }
}

.signature {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

[dark-theme] {
  .relatedWordsWrapper {
    background-color: var(--dark-theme-background-light-color);
    .tip {
      color: var(--dark-theme-font-main-color);
    }
  }

  .relatedWords li {
    color: var(--dark-theme-font-main-color);

    &:nth-child(odd) {
      background-color: var(--graphite-dark-color);
    }
  }
}

@import "src/screenSizes";

%buttonStyles {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 5px 15px;
  text-overflow: ellipsis;
  border-radius: 4px;
  display: flex;
  align-items: center;

  gap: 5px;

  &:disabled {
    color: var(--font-secondary-color);
    background-color: var(--button-background-disabled-color) !important;
  }
}

.basicModal {
  font-size: 18px;

  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;

  gap: 5px;

  .titleWrapper {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--font-main-color);
    padding-right: 15px;

    gap: 5px;

    & > svg {
      flex: 0 0 auto;
    }

    & > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .message {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--font-disabled-color);

    & > p {
      width: 340px;
      margin: 0 0 0 24px;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 0;

  gap: 5px;

  button {
    @extend %buttonStyles;
  }

  .extraButtonsWrapper {
    display: flex;
    flex: 1;
    gap: 5px;

    .extraButton {
      &:last-child {
        margin-right: auto;
      }
    }
  }

  .acceptButton {
    color: var(--font-light-color);
    background-color: var(--graphite-dark-color);
  }
}

[dark-theme] {
  .contentWrapper {
    .titleWrapper {
      color: var(--dark-theme-font-main-color);
    }

    .message {
      color: var(--dark-theme-font-disabled-color);
    }
  }

  .buttonsWrapper {
    .acceptButton {
      background-color: var(--dark-theme-background-accent-color);
    }
  }
}

@media (max-width: $mobileScreen) {
  .contentWrapper {
    .message {
      & > p {
        width: 300px;
      }
    }
  }
}

.words {
  margin-bottom: 25px;
  & > p {
    font-size: 14px;
    margin: 0 0 5px;
  }
}

.boldLabel {
  font-weight: 500;
}

.wordsWrapper {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  height: 150px;
  small {
    margin: 0;
  }
}

.word {
  margin-bottom: 7px;
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.loader {
  margin: auto;
}

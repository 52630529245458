@import '../../generalStyles/generalWidgetStyles.module.scss';

.widgetWrapper {
  width: auto;
  &List {
    width: 100%;
  }
}

.widgetWrapperOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.widgetWrapperInner {
  display: flex;
  justify-content: center;
}

.words {
  width: 100%;
  max-width: 700px;
  min-height: 500px;
  padding: 20px 0;
}

.error {
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  height: 500px;
  max-width: 500px;
}

.list {
  width: 100%;
  max-width: 100%;
}

.reviewDataButton {
  margin-right: auto;
  display: none;
}

[dark-theme] {
  .chartButton.active {
    background-color: var(--dark-theme-background-light-color);
  }
  .datePickerInput {
    background-color: var(--dark-theme-background-light-color);
    color: white;
    box-shadow: none;
    border: 1px solid var(--dark-theme-background-dark-color);
  }
  .aboutThis > div {
    background-color: var(--dark-theme-background-light-color);
  }
}

@media (min-width: 768px) {
  .dateAndBrand {
    justify-content: flex-end;
  }
  .datePickerInput {
    width: 150px;
  }
  .brandSelector {
    margin-left: 12px;
  }
  .filters {
    margin: 15px auto 0 auto;
    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .aboutThis {
    width: auto;
    margin-left: 50px;
  }
  .chartButtonsWrapper.center {
    margin: auto;
  }
  .widgetWrapperInner {
    padding: 0 20px;
  }
  .words {
    padding: 32px;
  }
  .reviewDataButton {
    display: flex;
  }
}

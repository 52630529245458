.brandSelector {
  margin-top: 9px;
  & > p {
    font-size: 10px;
    top: -2px;
  }
  & > div {
    max-width: 160px;
    height: 25px;

    & > div {
      &:nth-child(2) {
        top: initial;
        bottom: 0;
        transform: translateY(100%);
        & > div {
          max-height: 155px;
          & > div {
            line-height: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}

.horizontalFunnelChart {
  display: grid;
  grid-template-rows: auto min-content;
  width: 100%;
}

.main {
  flex: 1;
  display: flex;
  padding: 24px 0 6px;
}

.ticks {
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  opacity: 0.75;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    font-size: 10px;
    font-weight: 700;
  }
}

.blocksWrapper {
  position: relative;
  margin: 0 8px;
  flex: 1;
}

.ticksGrid {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    border-top: 1px solid rgba(#707070, 0.25);
  }
}

.blocks {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
}

.blockWrapper {
  flex: 1;
  position: relative;

  &:not(:first-child) {
    border-left: 1px solid rgba(#707070, 0.25);
  }
}

.block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.shapeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.label {
  position: absolute;
  left: 4px;
  right: 4px;
  top: -24px;
  display: flex;
  align-items: center;

  div {
    &:nth-child(1) {
      font-size: 11px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
      text-overflow: ellipsis;
    }

    &:nth-child(2) {
      flex: 1;
      margin-left: 8px;
      border-top: 1px solid;
    }
  }
}

.value {
  position: absolute;
  font-size: 22px;
  font-weight: 500;
}

.descriptions {
  margin: auto;
  width: calc(100% - (8px + 24px) * 2);
  display: flex;
  font-size: 12px;

  & > div {
    margin: 0 4px;
    flex: 1;
  }
}

.downloadExcelButton {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  font-size: 10px;
  background-color: var(--white-dark-color);
  box-shadow: 0px 3px 6px #00000029;

  & > svg {
    height: 12px;
    width: 12px;
  }

  & > span {
    margin-left: 4px;
  }
}

[dark-theme] {
  .downloadExcelButton {
    background-color: var(--dark-theme-background-light-color);
  }
}

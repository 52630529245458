@import "src/screenSizes";
@import "../../buttonsStyle.module.scss";

.form {
  display: flex;
  width: 100%;

  gap: 15px;

  .textarea {
    font-size: 24px;
    align-items: center;
    flex: 1 1;
    justify-content: space-between;
    height: 29px;
    padding: 0;
    border: 0;
    border-radius: 0;
    textarea {
      padding: 0;
    }
  }
}

.buttonRow {
  display: flex;

  gap: 5px;
}

@media screen and (max-width: $mobileScreen) {
  .form {
    .textarea {
      flex: auto;
    }
  }
  .buttonRow {
    margin-left: auto;
  }
}

[dark-theme] {
  .form {
    .textarea {
      background-color: var(--dark-theme-graphite-main-color);
    }
  }
}

.reputationIndexPieCharts {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.textArea {
  grid-area: text;
  font-size: 13px;
}

.widgetArea {
  grid-area: widget;
}

.widget {
  margin-top: 24px;
  display: grid;
  grid-gap: 64px 32px;
  grid-template:
    "pie1 pie2" max-content
    "pie3 pie4" max-content
    "pie5 pie6" max-content
    "pie0 blank" max-content / 1fr 1fr;
  padding-bottom: 24px;
}

.datesBar {
  position: absolute;
  right: 0;
  top: -92px;
}

.parameters {
  margin-left: 8px;
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.parameter {
  display: flex;
  font-size: 11px;
  & > div {
    &:nth-child(1) {
      flex-shrink: 0;
      margin-right: 8px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    &:nth-child(2) {
      & > span {
        &:nth-child(1) {
          font-weight: 500;
        }
        &:nth-child(2) {
          opacity: 0.75;
        }
      }
    }
  }
}

.chartArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartWrapperOuter {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100%;
}

.chartWrapperInner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.label {
  position: absolute;
  bottom: -24px;
  font-size: 16px;
}

@media (min-width: 768px) {
  .datesBar {
    right: 0;
    top: -92px;
    padding-left: 8px;
  }
}

@media (min-width: 992px) {
  .reputationIndexPieCharts {
    grid-template: "text widget" auto / 1fr 1fr;
  }

  .widget {
    grid-template:
      "pie1 pie2 pie3" max-content
      "pie4 pie5 pie6" max-content
      "blank1 pie0 blank2" max-content / 1fr 1fr 1fr;
  }
}

@media (min-width: 1440px) {
  .reputationIndexPieCharts {
    grid-template: "text widget" auto / 1fr 3fr;
  }

  .widget {
    grid-template:
      "pie0 pie1 pie2 pie3" max-content
      "pie0 pie4 pie5 pie6" max-content / 1fr 1fr 1fr 1fr;
  }
}

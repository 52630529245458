.errorPage {
    margin: 30px;
    height: calc(100% - 60px);
    justify-content: center;
    background-color: var(--background-main-color);
    display: flex;
}

.content {
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 30px;
}

.text{
    text-align: center;

    h1 {
        font-size: 34px;
        line-height: 45px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
    }
}

.image {
    max-width: 100%;
    padding: 0 30px;
}

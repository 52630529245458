@import "src/screenSizes";
@import "../../generalStyles/generalWidgetStyles.module.scss";
@import "../../generalStyles/generalLineChartStyles.scss";

.widgetWrapper {
  width: auto;
  margin: auto;
  &LineChart {
    width: 100%;
  }
}

.pieChart {
  width: 100%;
  min-height: 500px;
}

.note {
  font-size: 10px;
  margin-top: 24px;
  text-align: center;
}

@media screen and (max-width: $mobileScreen) {
  .pieChart {
    min-height: auto;
  }
}

@import "src/screenSizes";
@import "../../generalStyles/generalWidgetStyles.module.scss";
@import "../../generalStyles/generalLineChartStyles.scss";

.widgetWrapper {
  width: auto;
  &LineChart {
    width: 100%;
  }
}

.tableViewWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.pieChart {
  min-width: 300px;
  margin-top: 0;
  margin-left: 30px;
}

.pendingStub {
  display: flex;
  flex-direction: column;
  animation: radial-pulse 3s infinite;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;

  color: var(--font-disabled-color);

  gap: 10px;

  & > svg {
    width: 80%;
    height: 80%;
    max-height: 200px;
  }
}

@keyframes radial-pulse {
  0% {
  }
  50% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: $mobileScreen) {
  .tableViewWrapper {
    flex-direction: column;
  }

  .pieChart {
    margin-top: 30px;
    margin-left: 0;
  }
}

@import '../../generalStyles/generalWidgetStyles.module.scss';

.radarChart {
  width: 100%;
}

.widgetWrapper {
  width: 100%;
  max-width: 640px;
  padding: 32px;
  align-items: center;
}

.description {
  &Part1 {
    margin-top: 24px;
    font-size: 20px;
  }
  &Part2 {
    margin-top: 24px;
    font-size: 10px;
    text-align: center;
  }
  &Part3 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
  }
}

.wordWrapper {
  position: relative;
  padding: 7px 35px 7px 15px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: fit-content;
  &:hover {
    color: white;
    background-color: #bbbb77;
    & > button {
      display: block;
    }
  }
}

.excluded {
  border: 1px solid currentColor;
  &:hover {
    background-color: transparent;
    color: currentColor;
  }
  & > button {
    display: block;
  }
}

.text {
  max-width: 100%;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}

.button {
  display: none;
  height: 20px;
  position: absolute;
  right: 7.5px;
  top: 50%;
  transform: translateY(-50%);
}

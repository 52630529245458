.widgetPreview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  gap: 8px;

  .button {
    padding: 5px;

    &:first-of-type {
      margin-left: auto;
    }

    &.selected {
      opacity: 0.5;
    }
  }
}

.chartWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px;
}

.chart {
  height: 100%;
  cursor: pointer;
}

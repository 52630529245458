.emptyTable {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.emptyTableText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--font-disabled-color);
}

[dark-theme] {
  .emptyTableText {
    color: var(--dark-theme-font-disabled-color);
  }
}

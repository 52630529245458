@import "src/screenSizes";

%textStyle {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .9px;
}

.listWrapper {
  display: grid;
  align-items: flex-start;

  grid-template-columns: repeat(2, 1fr);
  gap: 55px;

  table {
    border-collapse: collapse;
  }

  tbody tr {
    height: 58px;
    border-bottom: 1px solid rgba(49,58,88,.3);
  }

  th,
  td {
    @extend %textStyle;
  }

  thead th {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  th.index {
    width: 35px;
  }

  th.value {
    width: 1px;
    padding-left: 25px;
    white-space: nowrap;
  }
}

.stub {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.sortButton {
  position: relative;
  display: flex;
  align-items: center;

  @extend %textStyle;
  svg {
    position: absolute;
    left: -5px;
    transition: all .1s ease-in;
    transform: translateX(-100%) rotate(0deg);
    transform-origin: center center;
  }
  &.descending {
    svg {
      transform: translateX(-100%) rotate(180deg);
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .listWrapper {
    grid-template-columns: repeat(1, 1fr);
    th,
    td {
      font-size: 12px;
    }
  }

  .stub {
    font-size: 14px;
  }

  .sortButton {
    font-size: 12px;
  }
}

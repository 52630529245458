.table {
  border: 1px solid var(--dark-theme-border-light-color);
  border-radius: 6px;
  background-color: var(--background-main-color);

  div[class~="rs-table-row-header"] {
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid var(--dark-theme-border-light-color);
  }

  div[class~="rs-table-cell-first"] > div {
    display: flex;
    align-items: center;
    padding-left: 14px;
  }

  div[class~="rs-table-row"],
  div[class~="rs-table-cell"] {
    font-size: 14px;
    overflow: initial;
  }

  div[class~="rs-table-row"]:hover {
    z-index: 1;
  }

  div[class~="rs-table-body-wheel-area"] {
    & > div {
      border-bottom: 1px solid var(--dark-theme-border-light-color);
    }
  }

  div[role~="columnheader"][aria-colindex~="2"] > div {
    overflow: initial;
  }

  span[class~="rs-table-cell-expand-wrapper"] {
    margin-right: initial;
  }

  .checkBox {
    margin-left: initial;
  }

  button[class~="rs-pagination-btn-active"] {
    border: 1px solid var(--border-main-color) !important;

    span {
      display: none;
    }
  }
}

.allKeyWordsCheckBox {
  display: flex;
  margin-right: 5px;
  margin-left: 16px;
  & > button {
    height: 20px;

    &:disabled {
      opacity: .5;
    }
  }
}

.totalVolumeWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  gap: 5px;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding: 15px;
  border-top: 1px solid var(--dark-theme-border-light-color);
}

.lineChart {
  width: 100%;
}

.tableLink {
  & > div {
    overflow: initial;
  }

  svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}

[dark-theme] {
  .table {
    border-color: transparent;
    background-color: var(--dark-theme-background-dark-color);

    div[class~="rs-table-row-header"] {
      border-bottom-color: var(--dark-theme-border-dark-color);
    }

    div[class~="rs-table-body-wheel-area"] > div {
      border-bottom-color: var(--dark-theme-border-dark-color);
    }
  }

  .totalVolumeWrapper > div {
    border-color: var(--dark-theme-background-dark-color);
    background-color: var(--dark-theme-graphite-main-color);
  }

  .tip {
    svg {
      & > circle {
        fill: var(--dark-theme-graphite-main-color)
      }

      & > path {
        fill: var(--white-main-color)
      }
    }
  }

  .paginationWrapper {
    border-top-color: var(--dark-theme-border-dark-color);
  }
}

@import "src/screenSizes";

.inputWrapper {
  font-size: 14px;
  line-height: 16px;
  position: relative;
  height: 40px;

  &:focus-within > input {
    border-color: var(--border-main-color);
  }

  & > input {
    font-size: inherit;
    font-weight: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    color: inherit;
    border: 1px solid var(--border-light-color);
    border-radius: 4px;
    background-color: var(--background-main-color);

    &::placeholder {
      color: var(--font-disabled-color);
    }
  }
}

[dark-theme] {
  .inputWrapper {
    border-color: var(--dark-theme-border-dark-color);
    background-color: var(--dark-theme-background-dark-color);

    &:focus-within > input {
      border-color: var(--dark-theme-border-main-color);
    }

    & > input {
      border-color: var(--dark-theme-border-dark-color);
      background-color: var(--dark-theme-background-dark-color);

      &::placeholder {
        color: var(--dark-theme-font-disabled-color);
      }
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .inputWrapper {
    height: 40px;

    input {
      height: 37px;
    }
  }
}

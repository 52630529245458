@import "src/screenSizes";

$imageSize: 350px;
$imageSizeMobile: 150px;

.additionalNames {
  display: flex;
  align-items: center;
  width: 1100px;
  max-width: 100%;
  padding: 70px;
}

.content {
  display: flex;
  flex: 1 1;
  flex-direction: column;

  gap: 30px;
}

.textWrapper {
  h6 {
    font-size: 24px;
    margin: 0;
  }

  p {
    font-size: 14px;
    margin: 0;
    opacity: .6;
  }
}

.contentWrapper {
  display: flex;
  align-items: flex-end;

  gap: 30px;
}

.names {
  display: flex;
  flex: 1 1;
  flex-direction: column;

  gap: 30px;
}

.form {
  position: relative;

  input {
    padding-right: 60px;
  }

  & > button[type="submit"] {
    font-size: 13px;
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 5px 10px;
    transform: translateY(-50%);
    color: var(--font-light-color);
    border-radius: 5px;
    background-color: var(--success-main-color);
  }
}

.doneBtn {
  width: 100%;
  max-width: 125px;
  height: 38px;
  margin-left: auto;
  color: var(--font-light-color);
  border-radius: 5px;
  background-color: var(--success-main-color);
}

.imageArea > div {
  width: $imageSize;
  height: $imageSize;
}

@media screen and (max-width: $tabletScreen) {
  .additionalNames {
    flex-direction: column-reverse;
    padding: 40px;
  }

  .imageArea > div {
    width: $imageSizeMobile;
    height: $imageSizeMobile;
  }

  .content {
    width: 100%;
  }

  .contentWrapper {
    flex-direction: column;
  }

  .names {
    width: 100%;
  }

  .additionalNames,
  .content,
  .contentWrapper,
  .names {
    gap: 16px;
  }
}

@media screen and (max-width: $mobileScreen) {
  .additionalNames {
    padding: 0px;
  }
}

.tabs {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: 0 15px;

  gap: 20px;
  border-bottom: 1px solid var(--border-light-color);

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--white-dark-color);
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 8px;
    background-color: #d7d9dd;
    background-clip: content-box;
  }
}

.tab {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
  padding: 15px 0;
  margin: 0;
  color: var(--font-disabled-color);
  white-space: nowrap;
  &Active {
    color: var(--font-main-color);
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: var(--graphite-dark-color);
    }
  }
}

[dark-theme] {
  .tabs {
    border-color: var(--dark-theme-border-dark-color);

    &::-webkit-scrollbar-track {
      background-color: var(--dark-theme-background-light-color);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--graphite-dark-color);
    }
  }

  .tab {
    color: var(--dark-theme-font-disabled-color);
    &Active {
      color: var(--dark-theme-font-main-color);
      &:after {
        background-color: var(--background-main-color);
      }
    }
  }
}

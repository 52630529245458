.quadrantChart {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 416px;
  padding: 16px;
}

.top {
  position: absolute;
  top: 0;
  right: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}

.right {
  position: absolute;
  top: 8px;
  right: 0;
  bottom: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 16px;
  .label {
    writing-mode: vertical-rl;
  }
}

.bottom {
  position: absolute;
  right: 8px;
  bottom: 0;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}

.left {
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 16px;
  .label {
    transform: rotate(180deg);

    writing-mode: vertical-rl;
  }
}

.label {
  font-size: 12px;
  font-weight: bold;
}

.main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--white-dark-color);
}

[dark-theme] {
  .main {
    background-color: var(--dark-theme-background-light-color);
  }
}

.horizontalLine {
  position: absolute;
  width: 100%;
  border-top: 2px solid #7345f6;
}

.verticalLine {
  position: absolute;
  height: 100%;
  border-left: 2px solid #7345f6;
}

.mark {
  position: absolute;
  width: 4px;
  height: 4px;
  transition: all .2s;
  border-radius: 50%;
  &Label {
    font-size: 10px;
    position: absolute;
    padding: 8px 12px;
    text-transform: uppercase;
    color: #fdfdfd;
    background-color: inherit;
  }
  &:hover {
    z-index: 6 !important;
  }
}

@import '../../generalStyles/generalPreviewStyles.module.scss';
@import "../../generalStyles/generalDropdownOnPreviewStyles.scss";

.charts {
  display: grid;
  height: 100%;
  cursor: pointer;

  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  column-gap: 16px;
}

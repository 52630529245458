@import "src/screenSizes";

.dashboards {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 15px 30px;
  height: calc(100% - var(--actionBar-height));
}

@media screen and (max-width: $tabletScreen) {
  .dashboards {
    padding: 15px 20px;
  }
}

.reputationSentimentLineChart {
  width: 100%;
}

.filters {
  font-size: 11px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;

  & > div {
    line-height: 16px;
    padding: 0 8px;
    text-transform: capitalize;
    border-left: 1px solid var(--pink-main-color);
    &:last-child {
      border-right: 1px solid var(--pink-main-color);
    }
  }
}

.lineChart {
  width: 100%;
  & > div:nth-child(1) {
    justify-content: space-between;
    & > div:nth-child(1) {
      justify-content: flex-start;
    }
  }
}

@import "src/screenSizes";

.actionBar {
  position: sticky;
  z-index: 1000;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}

.content {
  display: flex;
  height: var(--actionBar-height);
  padding: 10px 30px;
  background-color: var(--background-main-color);
  box-shadow: 0 0 24px rgba(31, 31, 31, .15);
}

.actionWrapper {
  display: flex;
  align-items: center;
}

.actions {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex: 1 1;
  justify-content: space-between;
  max-width: 100%;

  gap: 15px;

  & > div {
    display: flex;
    align-items: center;

    gap: 10px;
  }
}

.leftSide {
  overflow: hidden;
  flex: 1 1;
}

.rightSide {
  justify-content: flex-end;
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  overflow: hidden;
  flex: 1 1;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.footSection {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  background-color: var(--white-dark-color);

  gap: 15px;
}

.breadcrumbs {
  margin-right: auto;
}

.scrollButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0;
}

.plug {
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  width: 30px;
  height: var(--actionBar-height);
  background-color: var(--background-main-color);
}

[dark-theme] {
  .content {
    background-color: var(--dark-theme-graphite-main-color);
  }

  .footSection {
    background-color: var(--dark-theme-background-light-color);
  }

  .breadcrumbs {
    color: var(--dark-theme-font-active-color);

    span {
      color: var(--dark-theme-font-disabled-color);
    }
  }

  .plug {
    background-color: var(--dark-theme-graphite-main-color);
  }
}

@media screen and (max-width: $tabletScreen) {
  .content {
    padding: 10px 20px;
  }

  .leftSideActions {
    gap: 15px;
  }

  .footSection {
    padding: 15px 20px;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  [dark-theme] {
    .breadcrumbs {
      a:last-child,
      svg {
        color: var(--dark-theme-font-active-color);
      }
    }
  }
}

.lineChart {
  width: 100%;
  height: 100%;
  & > div:nth-child(1) {
    justify-content: space-between;
    & > div:nth-child(1) {
      justify-content: flex-start;
    }
  }
}

@import "src/screenSizes";
@import "../../generalStyles/generalWidgetStyles.module.scss";
@import "../../generalStyles/generalLineChartStyles.scss";

.widgetWrapper {
  width: auto;
  &LineChart {
    width: 100%;
  }
}

.pieChart {
  width: 416px;
}

@media (max-width: $mobileScreen) {
  .pieChart {
    width: 264px;
  }
}

@import "src/screenSizes";

.select {
  font-size: 14px;
  position: relative;
  height: 40px;

  &:focus-within > .input {
    border-color: var(--border-main-color);
  }
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  background-color: var(--background-main-color);

  gap: 5px;
}

[dark-theme] {
  .select:focus-within > .input {
    border-color: var(--dark-theme-border-main-color);
  }

  .input {
    border-color: var(--dark-theme-border-dark-color);
    background-color: var(--dark-theme-background-dark-color);
  }

  .placeholder {
    color: var(--dark-theme-font-disabled-color);
  }
}

.label,
.placeholder {
  line-height: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  width: calc(100% - 44px);
  padding: 0 0 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    flex: 0 0 auto;
    min-width: 18px;
    margin: 0 5px;
  }

  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.placeholder {
  color: var(--font-disabled-color);
}

.arrowIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;

  & > * {
    transition: all .2s;
  }
}

.popup {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  background-color: var(--background-main-color);
  box-shadow: 0 3px 6px #00000029;
  &Up {
    bottom: 45px;
    flex-direction: column-reverse;
  }
  &Down {
    top: 45px;
    flex-direction: column;
  }
}

.filterInput {
  font-size: 14px;
  font-weight: inherit;
  width: calc(100% - 16px);
  height: 32px;
  margin: 8px;
  padding: 0 8px;
  color: inherit;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .1);
  &::placeholder {
    opacity: .5;
    color: inherit;
  }
}

.options {
  overflow-y: auto;
  max-height: 202px;
  cursor: pointer;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .025);
  }
  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 0;
    background-color: rgba(0, 0, 0, .1);
  }
}

%option {
  line-height: 40px;
  overflow: hidden;
  height: 40px;
  padding: 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .7;
}

.option {
  transition: all .2s;

  @extend %option;
  &:hover {
    opacity: 1;
  }
}

.noOptions {

  @extend %option;
}

.message {
  border: 1px solid;
  border-radius: 4px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 7px;
  opacity: 1;
  gap: 7px;
  cursor: default;
  margin: 5px 10px;
  color: var(--font-main-color);

  &.success {
    background-color: var(--notification-success-background-color);
    border-color: var(--notification-success-border-color);
  }

  &.info {
    background-color: var(--notification-info-background-color);
    border-color: var(--notification-info-border-color);
  }

  &.warning {
    background-color: var(--notification-warning-background-color);
    border-color: var(--notification-warning-border-color);
  }

  &.error {
    background-color: var(--notification-error-background-color);
    border-color: var(--notification-error-border-color);
  }
}

[dark-theme] {
  .popup {
    border-color: var(--dark-theme-border-dark-color);
    background-color: var(--dark-theme-background-dark-color);
  }
}

@media screen and (max-width: $mobileScreen) {
  .select {
    height: 40px;
  }

  .placeholder,
  .label {
    line-height: 40px;
  }
}

.termsAndConditionsOfUse {
  min-height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 768px;
  padding: 24px;
}

.title {
  font-size: 24px;
}

.text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.4;
  opacity: 0.75;
}

@media (min-width: 768px) {
  .title {
    font-size: 48px;
  }
  .text {
    font-size: 18px;
  }
}

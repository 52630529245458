.placeholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.placeholderWrapper {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;

  color: var(--font-disabled-color);

  gap: 10px;

  & > svg {
    width: 80%;
    height: 80%;
    max-height: 200px;
  }
}

.placeholderAutomaticallyReloadWrapper {
  animation: radial-pulse 3s infinite;

  @extend .placeholderWrapper;
}

@keyframes radial-pulse {
  0% {
  }
  50% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
}

[dark-theme] {
  .placeholderWrapper {
    color: var(--dark-theme-border-main-color);
  }
}

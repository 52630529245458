@import "src/screenSizes";

.sentimentIndexPieCharts {
  display: grid;
  align-items: center;
  width: 100%;
  height: calc(100% - 24px);

  justify-items: center;
  gap: 20px;
  &3Elements,
  &4Elements,
  &5Elements {
    & > div {
      &:nth-child(1) {
        margin-right: initial;
      }
      &:nth-child(3) {
        margin-left: initial;
      }
    }
  }
  &4Elements {
    & > div {
      &:nth-child(4) {
        grid-column: 2;
      }
    }
  }
  &5Elements {
    & > div {
      &:nth-child(4) {
        margin-right: initial;
        transform: translateX(50%);
      }
      &:nth-child(5) {
        margin-left: initial;
        transform: translateX(-50%);

        grid-column: 3;
      }
    }
  }
}

.pieChartWrapper {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.pieChart {
  overflow: initial;
  height: 100%;
  max-height: 100%;
}

.brandName {
  font-weight: 600;
  position: absolute;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.labels {
  display: flex;
  justify-content: center;
  height: 24px;
}

.label {
  font-size: 10px;
  display: flex;
  align-items: center;
  color: currentColor;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.labelIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
}

@media screen and (max-width: $mobileScreen) {
  .sentimentIndexPieChartsPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 25px 0;

    gap: 40px;
    & > div {
      width: 100%;
    }
    &.sentimentIndexPieCharts3Elements,
    &.sentimentIndexPieCharts4Elements,
    &.sentimentIndexPieCharts5Elements {
      & > div {
        &:nth-child(1) {
          margin-right: auto;
        }
        &:nth-child(3) {
          margin-left: auto;
        }
      }
    }
    &.sentimentIndexPieCharts5Elements {
      & > div {
        &:nth-child(4) {
          margin-right: auto;
          transform: translateX(0);
        }
        &:nth-child(5) {
          margin-left: auto;
          transform: translateX(0);
        }
      }
    }
  }
}

.keyBusinessDriversChart {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 16px;
}

.top {
  position: absolute;
  left: 8px;
  right: 8px;
  top: 0;
  height: 16px;
  display: flex;
  align-items: center;
  & > div:not(:nth-child(2)) {
    flex: 1;
  }
  &Label {
    padding: 0 12px;
    font-size: 12px;
    font-weight: bold;
    color: var(--success-main-color);
  }
  &Line {
    border-top: 1px solid var(--success-main-color);
  }
}

.right {
  position: absolute;
  right: 0;
  top: 8px;
  bottom: 8px;
  width: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div:not(:nth-child(2)) {
    flex: 1;
  }
  &Label {
    padding: 12px 0;
    font-size: 12px;
    font-weight: bold;
    color: var(--success-main-color);
    writing-mode: vertical-lr;
  }
  &Line {
    border-right: 1px solid var(--success-main-color);
  }
}

.bottom {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 0;
  height: 16px;
  display: flex;
  align-items: center;
  & > div:not(:nth-child(2)) {
    flex: 1;
  }
  &Label {
    padding: 0 12px;
    font-size: 12px;
    font-weight: bold;
    color: var(--pink-main-color);
  }
  &Line {
    border-top: 1px solid var(--pink-main-color);
  }
}

.left {
  position: absolute;
  left: 0;
  top: 8px;
  bottom: 8px;
  width: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div:not(:nth-child(2)) {
    flex: 1;
  }
  &Label {
    padding: 12px 0;
    font-size: 12px;
    font-weight: bold;
    color: var(--pink-main-color);
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
  &Line {
    border-left: 1px solid var(--pink-main-color);
  }
}

.main {
  position: relative;
  height: 100%;
  background: transparent
    linear-gradient(219deg, #41b167 0%, #b14f57c3 67%, #ef336aa7 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  color: var(--gold-main-color);
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  &:nth-child(1) {
    left: 0;
    top: 0;
  }
  &:nth-child(2) {
    right: 0;
    top: 0;
  }
  &:nth-child(3) {
    left: 0;
    bottom: 0;
  }
  &:nth-child(4) {
    right: 0;
    bottom: 0;
  }
}

.horizontalLine {
  position: absolute;
  border-top: 2px solid #20263b;
  width: 100%;
}

.verticalLine {
  position: absolute;
  border-left: 2px solid #20263b;
  height: 100%;
}

.mark {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(32, 38, 59, 0.75);
  &Label {
    position: absolute;
    padding: 8px 12px;
    font-size: 10px;
    color: #efe9ea;
    text-transform: uppercase;
    background-color: rgba(32, 38, 59, 0.75);
  }
  &:hover {
    background-color: rgb(32, 38, 59);
    z-index: 4 !important;
    .markLabel {
      background-color: rgb(32, 38, 59);
    }
  }
}

.educateMeButton {
  display: flex;
  align-items: center;
  padding: 11px 24px;
  font-size: 14px;
  margin-left: auto;
  border: 1px solid var(--graphite-dark-color);
  border-radius: 4px;
  &:hover {
    text-decoration: none;
  }

  & > svg {
    width: 24px;
  }

  & > span {
    margin-right: 4px;
  }
}

[dark-theme] {
  .educateMeButton {
    border: 1px solid var(--white-main-color);
  }
}

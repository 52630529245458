@import "src/screenSizes";

.customizedLabelWrapper {
  width: 100%;
  min-width: 5px;
  height: 300px;
}

.customizedLabel {
  font-size: 12px;
  position: absolute;
  top: 8px;
  left: 25px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  gap: 10px;
}

.customizedLabelDate {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  color: var(--font-disabled-color);

  gap: 5px;

  & > svg {
    width: 8px;
    height: 8px;
  }

  &:first-of-type {
    & > svg {
      transform: rotate(90deg);
    }
  }

  &:last-of-type {
    & > svg {
      transform: rotate(-90deg);
    }
  }
}

.customizedLabelName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

[dark-theme] {
  .customizedLabelDate {
    color: var(--dark-theme-font-disabled-color);
  }
}

@media (max-width: $mobileScreen) {
  .customizedLabel {
    padding: 0 30px;
  }
}

@import "src/screenSizes";

.tableTitleWrapper {
  height: 55px;
}

.tableTitleItem {
  text-align: left;
}

.tableListItem {
  overflow: hidden;
  width: 130px;
  text-align: left;
  text-overflow: ellipsis;
}

.tableListItemIndex {
  width: 30px;
  height: 40px;
}

.brandName {
  font-weight: 700;
}

.shareOfVolumeTable {
  border-collapse: collapse;
  th:nth-child(1) {
    width: 30px;
  }

  th {
    width: 130px;
  }

  td {
    border-bottom: 1px solid black;
  }

  thead {
    display: block;
    border-bottom: 2px solid black;
  }

  tbody {
    display: block;
    overflow: auto;
  }
}

.negative {
  color: var(--pink-main-color);
}

.neutral {
  color: #e2b173;
}

.positive {
  color: #62b06f;
}

.tableTitleArrowWrapper {
  display: flex;
  align-items: center;
}

.tableTitleArrow {
  margin-right: 2px;
  transform: rotate(90deg);
}

@media screen and (max-width: $mobileScreen){
  .shareOfVolumeTable{
    font-size: 14px;
  }
}

.searchBar {
  position: relative;
  min-width: 285px;
}

.inputWrapper {
  position: relative;
  display: flex;
  .input {
    width: 100%;
    height: 40px;
    & > input {
      padding: 0 35px;
    }
  }

  & > .dandruffIcon {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .cleanButton {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 10px;
    height: 15px;
    transform: translateY(-50%);

    & > svg {
      fill: var(--border-main-color);
    }
  }
}

.dropDownWrapper {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  box-shadow: 0 0 16px rgba(31, 31, 31, .05);
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  overflow: hidden;
}

.dropDown {
  padding: 10px 15px;
  background-color: var(--background-main-color);
}

.resultListWrapper {
  display: flex;
  flex-direction: column;
  & > button {
    margin-left: auto;
  }
}

.viewResultsButton {
  font-size: 14px;
  color: var(--font-active-color);
}

.noResults {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--font-disabled-color);
  margin: 0;
}

[dark-theme] {
  .input,
  .dropDown {
    background-color: var(--dark-theme-graphite-main-color);
  }

  .dropDownWrapper {
    border-color: var(--dark-theme-border-dark-color);
  }

  .viewResultsButton {
    color: var(--dark-theme-font-active-color);
  }

  .noResults {
    color: var(--dark-theme-font-disabled-color);
  }
}

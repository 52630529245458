@import "../../generalStyles/generalPreviewStyles.module.scss";
@import "../../generalStyles/generalDropdownOnPreviewStyles.scss";

.buttons {
  justify-content: space-between;
  width: 100%;
}

.chartWrapper {
  position: relative;
}

.topKeywordsWidget {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  & > div {
    align-items: center;
    height: 100%;
  }
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
}

.list {
  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.listItem {
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .25);
  }

  .word {
    display: flex;
    justify-content: space-between;
  }

  .relatedWords {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px 0 0 0;
  }

  .openCloseButton {
    font-size: 16px;
    font-weight: 600;
    svg {
      transform: rotate(90deg);
    }
    &.opened {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

%generalWidgetStyle {
  font-size: 15px;
  font-weight: 500;
  padding: 5px 15px;
  color: var(--font-light-color);
  border-radius: 4px;
}

.editButton {
  background-color: var(--graphite-dark-color);

  @extend %generalWidgetStyle;
}

.redButton {
  background-color: var(--error-main-color);

  @extend %generalWidgetStyle;
}

.greenButton {
  background-color: var(--success-main-color);

  @extend %generalWidgetStyle;
}

[dark-theme] {
  .editButton {
    background-color: var(--pink-main-color);
  }
}

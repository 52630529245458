.withDrop {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px dashed #707070;
  }

  &Over {
    &::after {
      background-color: rgba($color: #707070, $alpha: 0.1);
    }
  }
}

$zIndexOfInfo: 3;

.speedometer {
  display: grid;

  grid-template-rows: min-content auto min-content;
}

.widgetWrapperOuter {
  position: relative;
}

.widgetWrapperInner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget {
  position: absolute;
}

.center {
  position: absolute;
  top: calc(2 / 3 * (100% - 24px));
  left: 50%;
}

.curvedLetter {
  font-size: 9px;
  font-weight: 400;
  position: absolute;
  top: -8px;
  left: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  text-transform: uppercase;
  z-index: $zIndexOfInfo;
  writing-mode: vertical-rl;
  & > div {
    transform: rotate(180deg);
  }
}

.mainTick {
  font-size: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $zIndexOfInfo;
  &Left{
    left: -30px;
  }
  &Center{
    left: -16px;
  }
}

.tick {
  font-size: 8px;
  font-weight: 900;
  position: absolute;
  left: -19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-light-color);
  z-index: $zIndexOfInfo;
  clip-path: polygon(100% 10%, 100% 90%, 0 100%, 0 0);
}

.roundPointer {
  position: absolute;
  display: flex;
  align-items: center;
  border-style: solid;
  border-radius: 50%;
  & > div {
    &:nth-child(1) {
      width: 100%;
      height: 100%;
      opacity: .5;
      border-style: solid;
      border-radius: 50%;
    }
    &:nth-child(2) {
      font-size: 8px;
      font-weight: 500;
      position: absolute;
    }
    &:nth-child(3) {
      position: absolute;
      height: 1px;
    }
    &:nth-child(4) {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}

.arrowPointer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
  pointer-events: none;
  border-radius: 50%;
  & > div {
    transition: inherit;
    &:nth-child(1) {
      font-size: 12px;
      font-weight: 500;
      color: #efe9ea;
    }
    &:nth-child(2) {
      position: absolute;
      border-top: 6px solid transparent;
      border-right-style: solid;
      border-bottom: 6px solid transparent;
      border-left: none;
    }
  }
  &Preview{
    & > div {
      &:nth-child(1) {
        font-size: 20px;
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: $zIndexOfInfo;
}

.headMessage {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  text-align: center;
  color: #8e8e63;
}

.message {
  font-size: 12px;
  font-weight: 500;
  align-self: center;
  padding: 2px 8px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #8e8e63;
  border: 1px solid #4f80e3;
}

.explanation {
  font-size: 14px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  text-align: center;
  text-transform: uppercase;
  & > div {
    &:nth-child(1) {
      color: var(--pink-main-color);
    }
    &:nth-child(3) {
      color: var(--success-main-color);
    }
  }
}

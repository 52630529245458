.signup {
  width: 100%;
  max-width: 568px;
  padding: 24px;
  margin: auto;
}

.logo,
.logoImage {
  height: 100px;
  &Wrapper {
    text-align: center;
  }
}

.title {
  font-size: 18px;
  margin-top: 16px;
  text-align: center;
}

.note1 {
  font-size: 12px;
  margin-top: 8px;
  text-align: center;
  opacity: .6;
}

.inputsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  & > div {
    max-width: 100%;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .input {
    width: 520px;
    max-width: 100%;
  }
}

.button {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 40px;
  margin-top: 16px;
  border-radius: 4px;
  &Green {
    color: var(--white-dark-color);
    background-color: var(--success-main-color);
  }
  &Blue {
    border: 1px solid #3a509b;
  }
}

.note2 {
  font-size: 11px;
  margin-top: 8px;
  opacity: .8;
  a {
    font-weight: 700;
  }
}

.selectedCountry {
  color: var(--pink-main-color);
}

@media (min-width: 768px) {
  .logo {
    height: 174px;
  }
  .logoImage {
    height: 150px;
  }
  .title {
    font-size: 32px;
    margin-top: 32px;
  }
  .note1 {
    font-size: 14px;
  }
  .inputsWrapper {
    margin-top: 24px;

    grid-gap: 24px;
    & > div {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    .input {
      width: calc(260px - 12px);
      &.email {
        width: 520px;
      }
    }
  }
  .button {
    margin-top: 32px;
  }
}

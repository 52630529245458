@import "src/screenSizes";

.sentimentDriversListView {
  width: 100%;
  margin-top: 25px;
}

.hiddenWords {
  margin-bottom: 40px;
  border-bottom: 1px solid #000;
  & > button {
    font-size: 16px;
    margin-bottom: 15px;
    svg {
      transform: rotate(90deg);
    }
  }

  &.opened > button {
    svg {
      transform: rotate(180deg);
    }
  }
}

.hiddenWordButtonContext {
  margin-left: 5px;
}

.positiveWordsLabel {
  & > span {
    color: #62af6e;
  }
}

.neutralWordsLabel {
  & > span {
    color: var(--gold-main-color);
  }
}

.negativeWordsLabel {
  & > span {
    color: #ef336b;
  }
}

.listsWrapper,
.hiddenWordsList {
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
}

.hiddenList {
  overflow: auto;
  max-height: 185px;
  padding-right: 5px;
}

@media (max-width: $mobileScreen) {
  .listsWrapper,
  .hiddenWordsList {
    grid-template-columns: repeat(1, 1fr);
  }
}

@import "src/screenSizes";

%lineStyles {
  width: 26px;
  height: 3px;
  transition: all .3s ease-out;
  background-color: var(--graphite-dark-color);
  border-radius: 6px;
}

.menu {
  position: relative;
  display: none;
  width: 30px;
  height: 20px;
  color: #efe9ea;
}

.line {
  display: block;

  @extend %lineStyles;

  &::before,
  &::after {
    position: absolute;
    left: 0;
    content: "";
  }

  &::before {
    top: 0;

    @extend %lineStyles;
  }
  &::after {
    bottom: 0;

    @extend %lineStyles;
  }
}

.menuOpen .line {
  width: 0;
  &:before {
    top: 50%;
    transform: translateY(-50%) rotate(225deg);
  }
  &:after {
    bottom: 50%;
    transform: translateY(50%) rotate(-225deg);
  }
}

[dark-theme] {
  %lineStyles {
    background-color: var(--background-main-color);
  }
}

@media screen and (max-width: $tabletScreen) {
  .menu {
    display: inline-block;
  }
}

.businessDriversBarChart {
  position: relative;
  width: 100%;
}

.datesBar {
  position: absolute;
  top: -92px;
  right: 0;
}

.textArea {
  grid-area: text;
  font-size: 13px;
}

.barChart {
  min-height: 414px;
  height: 100%;
}

@media (min-width: 992px) {
  .businessDriversBarChart {
    grid-template: "text widget" auto / 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .businessDriversBarChart {
    grid-template: "text widget" auto / 1fr 3fr;
  }
}

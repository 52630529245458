.wordTile {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  font-size: 14px;
  border: 1px solid #707070;
  border-radius: 4px;
  &Clickable {
    cursor: pointer;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border: inherit;
  border-radius: 50%;
  & > div {
    position: absolute;
    &:nth-child(1) {
      width: 11px;
      border-top: inherit;
    }
    &:nth-child(2) {
      height: 11px;
      border-left: inherit;
    }
  }
}

@import "src/screenSizes";

.loader {
  position: absolute;
  z-index: 10;
  top: -40px;
  left: -24px;
  width: calc(100% + 48px);
  height: calc(100% + 64px);
  min-height: calc(675px + 64px);
  background-color: var(--background-main-color);
}

.error {
  position: relative;
  display: flex;
  align-items: center;
  width: 600px;
  max-width: 100%;
  height: 200px;
  margin: auto;
  p {
    font-size: 24px;
    width: calc(100% - 200px);
    text-align: center;
  }
}

@media screen and (max-width: $tabletScreen) {
  .error {
    p {
      font-size: 16px;
      width: calc(100% - 100px);
      text-align: center;
    }
    .image {
      position: absolute;
      top: -25px;
      right: -100px;
      width: 300px;
    }
  }
}

[dark-theme] {
  .loader {
    background-color: var(--dark-theme-background-dark-color);
  }
}

@import "src/screenSizes";

.datesBar {
  font-size: 14px;
  line-height: 16px;
  min-height: 16px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: var(--font-disabled-color);
  & > div {
    display: flex;
    gap: 5px;
  }
}

.title {
  display: flex;
  align-items: center;
  & > svg {
    width: 14px;
    display: none;
  }
}

@media screen and (max-width: $tabletScreen) {
  .title {
    & > span {
      display: none;
    }
    & > svg {
      display: block;
    }
  }
}

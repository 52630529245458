.timePlotterRedirectButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.timePlotterRedirectButton {
  color: var(--font-active-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.modalForm {
  padding: 0;
  max-width: 940px;
  color: var(--font-main-color);

  & > *:last-child {
    button {
      padding: 5px 15px;
    }
  }
}

[dark-theme] {
  .timePlotterRedirectButton {
    color: var(--dark-theme-font-active-color);
  }

  .modalForm {
    color: var(--dark-theme-font-main-color);
  }
}

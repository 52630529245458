@import "src/screenSizes";

.verifyEmailPage {
  display: flex;
  height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 25px;
}

.logo {
  height: 174px;
  &Wrapper {
    text-align: center;
  }
}

.text {
  font-size: 18px;
  text-align: center;
}

.backToLogInPage {
  font-size: 14px;
  margin-top: 12px;
  text-decoration: underline !important;
  opacity: .7;
  &:hover {
    opacity: 1;
  }
}

@media screen and (max-width: $tabletScreen) {
  .logo {
    height: 100px;
  }
}

.formButtons {
  display: flex;

  gap: 15px;
  & > button {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    min-width: 100px;
    height: 40px;
    padding: 10px 15px;
    border-radius: 4px;
    &:disabled {
      color: var(--font-disabled-color);
      background-color: var(--button-background-disabled-color);
    }
  }
}

.cancelButton {
  border: 1px solid var(--border-main-color);
}

.resetButton {
  display: flex;
  align-items: center;
  color: var(--font-main-color);

  gap: 5px;

  span {
    color: var(--font-main-color);
  }

  svg {
    color: var(--graphite-dark-color);
  }
}

.submitButton {
  background-color: var(--success-main-color);
  color: var(--font-light-color);
}

[dark-theme] {
  .formButtons {
    & > button.resetButton > span {
      color: var(--dark-theme-font-main-color);
    }
  }

  .resetButton {
    svg {
      color: var(--white-main-color);
    }
  }
}

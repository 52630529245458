

.businessDriverPositioningPreview {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chartWrapper {
  flex: 1;
  padding: 8px;
}

.chart {
  height: 100% !important;
  min-height: 100% !important;
  cursor: pointer;
}

.placeholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

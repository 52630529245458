.searchesList {
  margin: 0;
  padding-left: 30px;
  max-height: 300px;
  overflow: auto;
}

.modalBody {
  margin: 0 24px;
}

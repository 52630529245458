.checkBoxCell {
  line-height: 46px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin-left: 16px;

  & > button {
    width: 20px;
    height: 20px;
  }

  &.group {
    margin-left: 0;
  }
}

.labelWrapper {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.group {
    font-weight: 500;
  }
}

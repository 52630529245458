@import "src/screenSizes";

.marketometerTable {
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  & > thead {
    box-shadow: 0 0 8px rgba(31, 31, 31, .1);
    & > tr {
      text-align: left;
      font-weight: 500;
    }
  }

  th,
  td {
    padding: 15px;
    font-size: 14px;
  }

  & > tbody {
    tr:not(:last-child) {
      td {
        border-bottom: 1px solid var(--border-light-color);
      }
    }
  }
}

.brandName {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dot{
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

@media screen and (max-width: $tabletScreen) {
  .marketometerTable {
    th,
    td {
      padding: 7px;
      font-size: 12px;
    }
  }
}

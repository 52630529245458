.home {
  display: flex;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: auto;
  flex: 1;
}

.initialPreloader {
  background-color: var(--white-dark-color) !important;
}

[dark-theme] {
  .initialPreloader {
    background-color: var(--dark-theme-background-light-color) !important;
  }
}

.reviewData {
  position: relative;
  width: 900px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.goBackButton {
  position: absolute;
  top: -8px;
  transform: translateY(-100%);
  padding: 5px 10px;
  background-color: var(--white-dark-color);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  h3 {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.52px;
    margin: 0;

    svg {
      margin-right: 15px;
    }
  }
}

.boldLabel {
  font-weight: 500;
}

.headerButtons {
  display: flex;
  gap: 30px;
}

.resetChangesBtn {
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
}

.aboutThisBtn {
  position: relative;
  z-index: 4;
  p {
    position: relative;
    color: #8e8e63;
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.68px;
    z-index: 2;
    svg {
      margin-right: 10px;
      transform: rotate(90deg);
    }
  }
  div {
    position: absolute;
    right: 0;
    top: 30px;
    width: 300px;
    span {
      position: relative;
      color: currentColor;
      text-align: left;
      z-index: 2;
      display: none;
    }
  }

  &.active {
    p {
      svg {
        transform: rotate(180deg);
      }
    }

    div {
      span {
        display: block;
      }
      &::before {
        content: "";
        position: absolute;
        top: -55px;
        left: -34px;
        width: calc(100% + 55px);
        height: calc(100% + 80px);
        box-shadow: 0 0 10px #0000004a;
        z-index: 1;
        background-color: var(--background-main-color);
        border-radius: 5px;
      }
    }
  }
}

.searchAndBrandSelector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 15px 0;

  .searchName {
    font-weight: 500;
    line-height: 30px;
    font-size: 18px;
  }
}

.select {
  max-width: 600px;
  z-index: 3;
}

.words {
  margin-bottom: 25px;
  & > p {
    font-size: 14px;
    margin: 0;
    margin-bottom: 5px;
  }
}

.brandSelector {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.brandSelectorLoader {
  margin: auto;
}

.brandButton {
  height: 28px;
  padding: 0 16px;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: opacity 0.2s;
  opacity: 0.75;
  margin: 0 10px 10px 0;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    opacity: 1;
  }
  &Active {
    border-color: #8e8e63;
    opacity: 1;
  }
}

.isMobile {
  display: flex;
  align-items: center;
  position: relative;
  p {
    width: calc(100% - 100px);
    text-align: center;
    font-size: 24px;
    margin: 75px 0;
  }
  .image {
    position: absolute;
    right: -68px;
    width: 250px;
  }
}

[dark-theme] {
  .aboutThisBtn.active div::before {
    background-color: var(--dark-theme-background-dark-color);
  }
  .goBackButton {
    background-color: var(--dark-theme-background-light-color);
  }
}

@media screen and (min-width: 1360px) {
  .reviewData {
    width: 1200px;
  }
  .header h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    flex-wrap: wrap;
    h3 {
      display: block;
      width: 100%;
      margin-bottom: 30px;
    }
  }
  .headerButtons {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .aboutThisBtn {
    margin-bottom: 20px;
    margin-right: auto;
    div {
      right: initial;
      left: 20px;
      width: 250px;
    }
    span {
      font-size: 12px;
    }
  }
  .searchAndBrandSelector {
    grid-template-columns: repeat(1, 1fr);
  }
}

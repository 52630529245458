.app {
  position: relative;
  margin: auto;
  min-width: 360px;
  max-width: 1920px;
  height: var(--app-height);
  color: var(--graphite-dark-color);
  background-color: var(--white-dark-color);
  overflow: hidden;
}

.app[dark-theme] {
  color: #efe9ea;
  background-color: var(--dark-theme-background-light-color);
}

.content {
  position: relative;
  height: 100%;
}

.section {
  height: 100%;
  overflow-y: auto;
}

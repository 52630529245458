@import "src/screenSizes";
@import "../../generalStyles/generalWidgetStyles.module.scss";
@import "../../generalStyles/generalLineChartStyles.scss";

.widgetWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &LineChart {
    width: 100%;
  }
}

@media screen and (max-width: $mobileScreen) {
  .sentimentIndexPieCharts {
    width: 100%;
    height: auto;
  }
  .filters {
    flex-wrap: wrap;
  }
  .dateRangePicker {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 15px;
  }
  .widgetWrapper {
    width: 100%;
    margin: auto;
    & > div {
      flex: 1 1 auto;
      width: 100%;
    }
  }
}

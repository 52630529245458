@import "src/screenSizes";

.dashboardToFolderModal {
  width: 340px;
  max-width: 100%;
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  gap: 10px;
  svg {
    color: var(--success-main-color);
  }
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  & > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: inline-block;
    margin: 0 5px;
    color: var(--font-disabled-color);
  }
  &:before,
  &:after {
    flex: 1 1;
    height: 1px;
    content: "";
    background-color: var(--border-light-color);
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  button {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 5px 15px;
    border-radius: 4px;
    &[type = "submit"] {
      color: var(--font-light-color);
      background-color: var(--success-main-color);
    }
  }
}

@media screen and (max-width: $mobileScreen) {
  .dashboardToFolderModal {
    width: initial;
  }
}

.toggle {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  &Icon {
    position: absolute;
    left: -12px;
    width: 8px;
    transition: all 0.2s;
    transform: rotate(90deg);
  }
}

.arrowDown {
  transform: rotate(180deg);
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
}

.leftText {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    margin-left: 4px;
    content: attr(data-optional-label);
    opacity: .6;
  }
}

.rightText {
  font-size: 10px;
  flex-shrink: 0;
  margin-left: 16px;
}

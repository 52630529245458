@import "src/screenSizes";

$iconSize: 20px;

.actionBarWrapper {
  width: 310px;
}

.iconWrapper {
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
}

.dandruffIcon {
  width: $iconSize;
  height: $iconSize;
  margin: 9px;
}

@media screen and (max-width: $tabletScreen) {
  .actionBarWrapper {
    width: initial;
  }

  .iconWrapper {
    display: flex;
  }

  .actionBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);

    & > div {
      bottom: 0;
    }
  }
}

$iconSize: 18px;

%option {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 10px 20px;
  opacity: .75;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}

.menuDropdown {
  font-size: 11px;
  position: relative;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.dots {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: transform .2s;
  transform: rotate(0deg);
  transform-origin: center;
  span {
    position: absolute;
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: currentColor;
    &:first-child {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(2) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:last-child {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.menuDropdown.open {
  .dots {
    transform: rotate(-90deg);
  }
}

.menu {
  position: absolute;
  z-index: 2;
  overflow-y: auto;
  max-width: 200px;
  max-height: 320px;
  opacity: 0;
  border-radius: 4px;
  background: var(--background-main-color);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  &.up {
    bottom: 16px;
    opacity: 1;
  }
  &.down {
    top: 16px;
    opacity: 1;
  }
  &.left {
    right: 0;
    opacity: 1;
  }
  &.right {
    left: 16px;
    opacity: 1;
  }
}

.option {
  cursor: pointer;
  transition: all .2s;

  @extend %option;
  &:not(:first-child) {
    border-top: 1px solid rgba(#707070, .25);
  }
  &:hover {
    opacity: 1;
  }
}

.icon {
  width: $iconSize;
  min-width: $iconSize;
  height: $iconSize;
  margin-right: 5px;
}

.label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noOptions {
  @extend %option;
}

[dark-theme] {
  .menu {
    background-color: var(--dark-theme-background-dark-color);
  }
}


@import "src/screenSizes";

.topTrendKeywordsReviewData {
  position: relative;
  display: flex;
  min-height: 575px;
  padding-top: 30px;
}

.content {
  margin-top: 25px;
}

.wordsWrapper {
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.excludeButton {
  font-size: 20px;
  line-height: 24px;
  float: right;
  margin-top: 25px;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  background: var(--success-main-color);
}

.word {
  display: inline-flex;
  max-width: calc(100% - 15px);
  margin-bottom: 7px;
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.notExcludedWord {
  color: white;
  background-color: #bb7;
  & > button {
    display: block;
  }
}

.excludedWord {
  color: black;
  border: 1px solid black;
  & > button {
    display: block;
  }
  &:hover {
    color: black;
    background-color: transparent;
    & > button {
      color: black;
    }
  }
}

.addMoreWordsButton {
  font-size: 20px;
  line-height: 24px;
  float: right;
  margin-top: 25px;
  padding: 10px 10px;
  cursor: pointer;
  color: var(--success-main-color);
  border: 1px solid var(--success-main-color);
  border-radius: 5px;
}

.addMoreWordsButton:disabled {
  color: var(--font-disabled-color);
  border-color: var(--border-main-color);
}

.excludeButton:disabled {
  background-color: var(--button-background-disabled-color);
  color: var(--font-disabled-color);
}

[dark-theme] {
  .excludedWord {
    color: white;
    border: 1px solid white;
    &:hover {
      color: white;
      & > button {
        color: white;
      }
    }
  }

  .excludeButton:disabled,
  .addMoreWordsButton:disabled {
    color: var(--dark-theme-font-disabled-color);
  }
}

@media screen and (max-width: $tabletScreen) {
  .topTrendKeywordsReviewData {
    min-height: 535px;
  }
  .wordsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .excludeButton {
    margin-top: 25px;
    margin-left: auto;
  }
}

@media screen and (max-width: $mobileScreen) {
  .topTrendKeywordsReviewData {
    min-height: initial;
  }
}

@import "src/screenSizes";

$iconSize: 20px;

.multiSelect {
  position: relative;
  display: flex;
  width: var(--additional-button-width);

  &.Opened {
    .triangle svg {
      transform: rotate(0);
    }
  }
}

.multiSelectButton {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1;
  width: 100%;
  padding: 10px 50px 10px 15px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--success-dark-color);

  gap: 10px;

  &:disabled {
    opacity: .5;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    width: $iconSize;
    height: $iconSize;
  }
}

.triangle {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 0 4px 4px 0;
  background: var(--success-main-color);
  svg {
    width: 10px;
    height: 10px;
    transform: rotate(180deg);
  }
}

.dropDown {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  padding: 0 15px;
  transform: translateY(100%);
  border: 1px solid var(--border-light-color);
  border-radius: 4px;
  background-color: var(--background-main-color);
  box-shadow: 0 0 24px rgba(31, 31, 31, .05);
}

.option {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 10px 0;

  gap: 5px;

  svg {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: $tabletScreen) {
  .multiSelect {
    width: auto;
  }

  .triangle {
    position: static;
    border-radius: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .dropDown {
    right: 0;
    left: initial;
    min-width: 200px;
  }

  .multiSelectButton {
    padding: 0;
    span {
      display: none;
    }
  }
}

[dark-theme] {
  .dropDown {
    border-color: var(--dark-theme-graphite-main-color);
    background-color: var(--dark-theme-background-light-color);
  }
}

.checkboxButtonStyle {
  width: 16px;
  height: 16px;
  padding: 0;
  border: .5px solid #b5b5b5;
  border-radius: 50%;
  background-color: var(--background-main-color);
  box-shadow: 0 3px 6px #00000029;
}

.checkboxButtonContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxButtonLabelText {
  font-size: 15px;
  margin-right: 5px;
}

.checkboxButtonStyleGroupCheck {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  svg {
    transition: all .2s;
    opacity: 0;
  }

  &.checked {
    border: none;
    background-color: var(--success-main-color);

    svg {
      opacity: 1;
      fill: var(--white-main-color);
    }
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

[dark-theme] {
  .widget {
    span {
      background-color: var(--dark-theme-background-dark-color);
    }
  }

  .buttonGroup {
    .buttonGroupCheck {
      svg {
        fill: var(--graphite-dark-color);
      }

      &.checked {
        svg {
          fill: var(--white-main-color);
        }
      }
    }
  }
}

@import "src/screenSizes";

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 15px 30px;
  border-radius: 8px;
  background-color: var(--background-main-color);

  & > *:last-child {
    border-radius: 8px;
  }
}

[dark-theme] {
  .page {
    background-color: var(--dark-theme-background-dark-color);
  }
}

@media screen and (max-width: $tabletScreen) {
  .page {
    margin: 15px 20px 20px;
  }
}

%fontStyles {
  font-size: 14px;
  line-height: 20px;
}

.tableCardWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0 0;
  border-bottom: 1px solid var(--border-light-color);

  &:last-child {
    border-bottom: none;
  }

  .clickable {
    font-size: inherit;
    overflow: hidden;
    width: 100%;
    text-align: start;
    text-overflow: ellipsis;
    color: var(--font-active-color);
  }
}

.tableCardHeader {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px 15px;

  gap: 10px;
  @extend %fontStyles;

  .headerName {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;

    & > span {
      display: flex;
      align-items: center;

      gap: 5px;

      svg {
        flex: 0 0 auto;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .headerAction {
    display: flex;
    align-items: center;
    margin-left: auto;

    gap: 10px;

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.tableCardBody {
  display: flex;
  flex-direction: column;
  height: 0;

  &.isBodyCollapsed {
    height: 100%;
  }

  .tableCardWrapper {
    padding: 15px 0 0;

    .tableCardHeader {
      padding-left: 45px;
    }

    .tableCardInfoWrapper {
      display: flex;
      flex-direction: column;
      padding: 0 30px 15px 75px;

      gap: 10px;
      @extend %fontStyles;
    }
  }
}

.tableCardInfoWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 30px 15px 45px;

  gap: 10px;
  @extend %fontStyles;
}

.tableCardInfo {
  display: grid;

  grid-template-columns: 1fr 1fr;

  .infoName {
    font-weight: 500;
  }

  .infoValue {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.tableCardActions {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;

  .tableCardAction {
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;

    gap: 5px;
    @extend %fontStyles;

    svg {
      width: 16px;
      height: 16px;
      text-align: center;
    }
  }
}

[dark-theme] {
  .tableCardWrapper {
    border-color: var(--dark-theme-border-dark-color);

    .clickable {
      color: var(--dark-theme-font-active-color);
    }
  }
}

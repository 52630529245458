@import "src/screenSizes";

$iconSize: 20px;

.reviewDataButton {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--additional-button-width);
  padding: 10px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--success-dark-color);

  gap: 10px;

  &:disabled {
    opacity: .5;
  }

  svg {
    width: $iconSize;
    height: $iconSize;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: $tabletScreen) {
  .reviewDataButton {
    width: auto;
    padding: 10px;

    & > span {
      display: none;
    }
  }
}

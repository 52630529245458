@import "src/screenSizes";

.words {
  overflow: auto;
  height: 518px;
  padding: 15px;
  border: 1px solid #bcbec5;
  border-radius: 5px;
}

.filterWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.title {
  font-size: 14px;
  margin: 0 15px 0 0;
  letter-spacing: .7px;
  opacity: .5;
}

.filter {
  font-size: 14px;
  flex: 1 1;
  max-width: 200px;
  height: 30px;
}

.word {
  display: inline-flex;
  max-width: calc(100% - 15px);
  margin-bottom: 7px;
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.wordNotImpact {
  background-color: grey !important;
  &:hover {
    background-color: grey;
  }
}

@media screen and (max-width: $tabletScreen) {
  .words {
    height: initial;
    height: 160px;
  }
}

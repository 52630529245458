@import "src/screenSizes";

.widgetButtonsBar {
  display: flex;
  align-items: center;
}

.rightSide {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  padding-top: 20px;
  z-index: 11;
  position: relative;
}

@media screen and (max-width: $tabletScreen) {
  .widgetButtonsBar {
    align-items: flex-start;
    flex-direction: column;

    gap: 25px;
  }
}

[dark-theme] {
  .chartButtonActive {
    background-color: var(--dark-theme-background-light-color);
  }
}

.tooltip {
  line-height: 14px;
  z-index: 3;
  max-width: 176px;
  padding: 8px;
  color: #20263b;
  background-color: #efe9ea;

  &Title {
    font-size: 14px;
    margin-bottom: 4px;
  }

  &Row {
    font-size: 11px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

@import "../../generalStyles/generalWidgetStyles.module.scss";

.verticalFunnelWidgets {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  gap: 32px;
}

.verticalFunnelChartWrapper {
  flex: 1 1;
  max-width: 406px;
}

@media (min-width: 768px) {
  .verticalFunnelWidgets {
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .verticalFunnelChartWrapper {
    width: 100%;
  }
}

@import "src/screenSizes";

%generalTextStyles {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.shareDashboardModal {
  display: flex;
  flex-direction: column;
  min-width: 340px;
  max-width: 100%;

  gap: 20px;
}

.head {
  display: flex;
  align-items: center;

  gap: 10px;
  svg {
    color: var(--success-main-color);
  }
  span {
    font-weight: 500;
  }
}

.emailSender {
  display: flex;
  align-items: flex-end;

  gap: 10px;
}

.email {
  margin-top: 10px;
  flex: 1 1;
}

.label {
  font-size: 14px;
  font-weight: 500;
}

.input {
  height: 40px;
}

.input,
.label {
  color: var(--font-main-color);
}

.submitButton {
  padding: 10px 15px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--success-main-color);

  @extend %generalTextStyles;

  &:disabled {
      color: var(--font-disabled-color);
      background-color: var(--button-background-disabled-color);
  }
}

.footButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyLinkButton {
  display: flex;
  color: var(--font-active-color);
  padding: 5px 0 !important;

  gap: 5px;

  @extend %generalTextStyles;

  svg {
    fill: var(--font-active-color);
  }
}

.doneButton {
  min-width: 100px;
  padding: 10px 15px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--graphite-dark-color);

  @extend %generalTextStyles;
}

[dark-theme] {
  .input,
  .label {
    color: var(--dark-theme-font-main-color);
  }

  .copyLinkButton {
    color: var(--dark-theme-font-active-color);

    svg {
      fill: var(--dark-theme-font-active-color);
    }
  }
}

@media (max-width: $mobileScreen) {
  .shareDashboardModal {
    min-width: 100%;
  }
}

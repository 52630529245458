%generalTextStyles {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.listWrapper {
  ul {
    padding: 0;
  }
  p,
  li {
    margin: 0;

    @extend %generalTextStyles;
  }


  li {
    list-style-type: none;
  }
}

.title {
  margin-bottom: 10px;
}

.list {
  margin: 0;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 10px 0;

  gap: 10px;

  & > .mainButton {
    display: flex;
    overflow: hidden;
    align-items: center;
    flex: 1 1;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: inherit;

    gap: 10px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      min-width: 20px;
    }
  }

  & > .additionalButton {
    height: 15px;
    & > svg {
      fill: var(--border-main-color);
    }
  }
}

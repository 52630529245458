.upgradePlanSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 90px;
  margin: 20px 20px 0;
  padding: 15px;
  color: var(--font-light-color);
  border-radius: 4px;
  background-color: var(--graphite-main-color);

  gap: 10px;

  & > * {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > button {
    margin: 0;
    padding: 5px 15px;
    text-align: center;
    border-radius: 4px;
    background-color: var(--success-main-color);
  }

  & > span {
    text-align: center;
    text-transform: capitalize;
  }
}

.upgradePlanButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 65px auto 15px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--success-main-color);
}

@import "../../generalStyles/generalPreviewStyles.module.scss";
@import "../../generalStyles/generalDropdownOnPreviewStyles.scss";

.chartWrapper {
  flex-direction: row;
  & > div {
    width: 100%;
  }
}

.buttons {
  justify-content: flex-end;
  width: 100%;
}

.brandSelector {
  margin-right: auto;
}

.sentimentDriversTagCloud {
  height: 100%;
  cursor: pointer;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100%;
}

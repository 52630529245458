.loaderWrapper {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--background-main-color);
}

[dark-theme] {
  .loaderWrapper {
    background-color: var(--dark-theme-background-dark-color);
  }
}

.esgIndexPieCharts {
  position: relative;
  display: flex;
  grid-gap: 32px;
}

.datesBar {
  position: absolute;
  right: 0;
  top: -92px;
}

.textArea {
  grid-area: text;
  font-size: 13px;
}

.parameters {
  margin-left: 8px;
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.widget {
  display: grid;
  grid-gap: 64px 32px;
  grid-template:
    "pie1 pie2" max-content
    "pie3 pie0" max-content / 1fr 1fr;
  padding-bottom: 24px;
}

.chartArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartWrapperOuter {
  position: relative;
  width: 100%;
}

.label {
  text-align: center;
}

@media (min-width: 992px) {
  .widget {
    grid-template:
      "pie1 pie2 pie3" max-content
      "blank1 pie0 blank2" max-content / 1fr 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .esgIndexPieCharts {
    grid-template: "text widget" auto / 1fr 3fr;
  }

  .widget {
    grid-template: "pie0 pie1 pie2 pie3" 100% / 1fr 1fr 1fr 1fr;
  }
}
